import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.min.css";

import { isLaurence, VerificaBackTo } from "../../service/auth";
import { dadosToken, filtrar, filtrarDataInicioFim, formataNome, isEmpty } from "../../components/Util";
import Botao, { BotaoDeletar, BotaoEditar, BotaoGrupo, BotaoSalvar, BotaoVoltar } from "../../components/Botao";
import ModalPadrao, { ModalSubmit } from "../../components/ModalPadrao";
import InputFile from "../../components/InputFile";
import SpanBadge from "../../components/SpanBadge";
import TabelaPadrao from "../../components/TabelaPadrao";
import Template from "../template/Template";
import api from "../../service/api";
import moment from "moment";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Filter from "../../components/Filter";
import ModalAvaliacaoPsicossocial from "./relatorios/ModalAvaliacaoPsicossocial";

const Pgr = () => {
  const [dadosClienteEstab, setDadosClienteEstab] = useState([]);
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [showModalEstab, setShowModalEstab] = useState(false);
  const [showModalART, setShowModalART] = useState(false);
  const [showPCMSO, setShowPCMSO] = useState(false);
  const [showModalAvaiacaoPsicossocial, setShowMOdalAvaliacaoPsicossocial] = useState(false);
  const [showOutrosDocumentos, setShowOutrosDocumentos] = useState(false);
  const [showInformativos, setShowInformativos] = useState(false);
  const [showAET, setShowAET] = useState(false);
  const [arquivoNome, setArquivoNome] = useState();
  const [url, setUrl] = useState("");
  const [modulo, setModulo] = useState();

  const [showModalUploadPDF, setShowModalUploadPDF] = useState(false);
  const [ergonomia, setErgonomia] = useState(false);
  const [botao, setBotao] = useState();
  const [isInventario, setIsInventario] = useState(false);
  const [isFuncoesColaboradores, setIsFuncoesColaboradores] = useState(false);
  const [acesso, setAcesso] = useState();
  const [acessoDocumento, setAcessoDocumento] = useState();
  const [documentos, setDocumentos] = useState();
  const [documentoId, setDocumentoId] = useState();
  const [existeEnsaios, setExisteEnsaios] = useState();

  const [showModalHistorico, setShowModalHistorico] = useState(false);
  const [showPlanoAcao, setShowPlanoAcao] = useState(false);

  const [loading, setLoading] = useState(true);

  const { clienteId } = useParams();

  useEffect(() => {
    api.get(`clientes/obterDocumentosRevisao?cliente_id=${clienteId}`).then((resposta) => {
      setDocumentos(resposta.data);

      api.get(`clientes/obterPgrAcesso?cliente_id=${clienteId}`).then((resposta) => {
        setAcesso(resposta.data);
        api.get(`clientes/obterPgrAcessoDocumento?cliente_id=${clienteId}`).then((resposta) => {
          setAcessoDocumento(resposta.data);

          api
            .get("clientes/obterClienteComEstabelecimentos", {
              params: {
                cliente_codigo: clienteId,
              },
            })
            .then((resposta) => {
              setEstabelecimentos(resposta.data.cliente_estabelecimentos);

              api.get(`clientes/obterDadosEnsaioVedacao/${clienteId}`).then((ensaios) => {
                setExisteEnsaios(!isEmpty(ensaios.data));
                setLoading(false);
              });
            });
        });
      });
    });
  }, [clienteId]);

  const abrirModal = (url, modulo = "ppra", documentoId = null) => {
    if (acesso.status === "I") {
      toast.info("Documento em Revisão");
    } else {
      if (!url) {
        toast.info("Não existem dados cadastrados");
      } else {
        let urlPadrao = process.env.REACT_APP_API_URL + "/gro/pgr/pdf/" + url;
        setIsInventario(
          (url === "quadros/gerar/" ||
            url === "quadros/gerarRevisao/" ||
            url === "apres/gerarRevisao/" ||
            url === "acidente/gerarRevisao/") &&
            true
        );

        if (estabelecimentos.length > 1) {
          setShowModalEstab(true);
          setUrl(urlPadrao);
          setModulo(modulo);
          setIsFuncoesColaboradores(url === "funcoesFuncionarios/gerar/" && true);
        } else {
          buscarDadosCliente(
            estabelecimentos[0].codigo,
            modulo,
            urlPadrao,
            documentoId,
            (url === "quadros/gerar/" ||
              url === "quadros/gerarRevisao/" ||
              url === "apres/gerarRevisao/" ||
              url === "acidente/gerarRevisao/") &&
              true
          );
        }
      }
    }
  };

  async function buscarDadosCliente(clienteEstabelecimentoId, modulo, urlPadrao, documentoId, isInventario) {
    const alertFrase = "Não existem dados cadastrados";
    await api
      .get("gro/pgr/painel", {
        params: { clienteEstabelecimentoId: clienteEstabelecimentoId },
      })
      .then((resposta) => {
        setDadosClienteEstab(resposta.data);
        api.get(`clientes/obterAcessoPDF/${resposta.data.codigo}`).then((acesso) => {
          if (isInventario) {
            if (documentoId === "2") {
              switch (acesso.data.find((item) => item.id === documentoId).ppra_id) {
                case "1184":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "PoloFilms-Sede-PGR Inventário-RiscosFísicos,Químico_Biológicos-rev00.pdf"
                  );
                  break;

                case "1311":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Polo Films - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00a.pdf"
                  );
                  break;

                case "1376":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Polo Films - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev01.pdf"
                  );
                  break;

                case "1463":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Polo Films - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev01a.pdf"
                  );
                  break;
                case "1264":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "Best-Base_Esteio-Sede-PGR_Inventário-Riscos_Físicos_Químicos_e_ Biológicos-rev00.pdf"
                  );
                  break;

                case "1259":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "ProlecGe-Sede-PGRInventário-RiscosFísicos,QuímicoseBiológicos-rev00a.pdf"
                  );
                  break;

                case "1247":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Nexteer - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1265":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Videolar-innova - Unid. I - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1297":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Gremio Nautico Uniao - Gnu Ap - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1304":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Medicone - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1392":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Medicone - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00a.pdf"
                  );
                  break;

                case "1262":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Springer Carrier - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1445":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Springer Carrier - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev01.pdf"
                  );
                  break;

                case "1326":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Prolec Ge - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00b.pdf"
                  );
                  break;

                case "1306":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Ball - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00a.pdf"
                  );
                  break;

                case "1273":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Trevo - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1339":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Nexteer - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00a.pdf"
                  );
                  break;

                case "1300":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Solae - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1340":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Brasilit - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1458":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Prolec Ge - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev01.pdf"
                  );
                  break;

                case "1442":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Prolec Ge - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev01a.pdf"
                  );
                  break;

                case "1290":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Videolar-innova - Unid. Iv - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                case "1494":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Restinga - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00b.pdf"
                  );
                  break;
                case "1493":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.1 - Trevo - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00a.pdf"
                  );
                  break;

                  case "1479":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD + "1.1 - Hospital Sao Lucas - Pucrs - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
                  );
                  break;

                default:
                  if (resposta.data.ultimo_documento_base) {
                    if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                      if (dadosToken().tipo === "2") {
                        toast.info(alertFrase);
                      } else {
                        toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                      }
                    } else {
                      window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id);
                    }
                  } else {
                    if (dadosToken().tipo === "2") {
                      toast.info(alertFrase);
                    } else {
                      toast.info("Não foi cadastrado o documento base");
                    }
                  }
                  break;
              }
            } else if (documentoId === "3") {
              switch (acesso.data.find((item) => item.id === documentoId).ppra_id) {
                case "1184":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "Polo Films - Sede - Inventario - Risco Ergonômico - rev00.pdf"
                  );
                  break;

                case "1311":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Polo Films - Sede - Inventário - Risco Ergonômico - rev00a.pdf"
                  );
                  break;

                case "1376":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Polo Films - Sede - Inventário - Risco Ergonômico - rev01.pdf"
                  );
                  break;

                case "1463":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Polo Films - Sede - Inventário - Risco Ergonômico - rev01a.pdf"
                  );
                  break;

                case "1259":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "Prolec Ge - Sede - PGR Inventário - Risco Ergonômico - rev00a.pdf"
                  );
                  break;

                case "1247":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Nexteer - Sede - Inventário - Risco Ergonômico - rev00.pdf"
                  );
                  break;

                case "1273":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Trevo - Sede - Inventário - Risco Ergonômico - rev00.pdf"
                  );
                  break;

                case "1275":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Restinga - Sede - Inventário - Risco Ergonômico - rev00.pdf"
                  );
                  break;

                case "1362":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Restinga - Sede - Inventário - Risco Ergonômico - rev00a.pdf"
                  );
                  break;

                case "1494":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Restinga - Sede - Inventário - Risco Ergonômico - rev00b.pdf"
                  );
                  break;

                case "1493":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Trevo - Sede - Inventário - Risco Ergonômico - rev00a.pdf"
                  );
                  break;

                case "1304":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Medicone - Sede - Inventário - Risco Ergonômico - rev00.pdf"
                  );
                  break;

                case "1392":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Medicone - Sede - Inventário - Risco Ergonômico - rev00a.pdf"
                  );
                  break;

                case "1326":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Prolec Ge - Sede - Inventário - Risco Ergonômico - rev00b.pdf"
                  );
                  break;

                case "1306":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Ball - Sede - Inventário - Risco Ergonômico - rev00a.pdf"
                  );
                  break;

                case "1321":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Polo Films - Sede - Inventário - Risco Ergonômico - rev00b.pdf"
                  );
                  break;

                case "1339":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Nexteer - Sede - Inventário - Risco Ergonômico - rev00a.pdf"
                  );
                  break;

                case "1300":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Solae - Sede - Inventário - Risco Ergonômico - rev00.pdf"
                  );
                  break;

                case "1458":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Prolec Ge - Sede - Inventário - Risco Ergonômico - rev01.pdf"
                  );
                  break;

                case "1442":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.2 - Prolec Ge - Sede - Inventário - Risco Ergonômico - rev01a.pdf"
                  );
                  break;
                
                case "1479":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD + "1.2 - Hospital Sao Lucas - Pucrs - Sede - Inventário - Risco Ergonômico - rev00.pdf"
                  );
                  break;

                default:
                  if (resposta.data.ultimo_documento_base) {
                    if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                      if (dadosToken().tipo === "2") {
                        toast.info(alertFrase);
                      } else {
                        toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                      }
                    } else {
                      if (
                        typeof acesso.data.find((item) => item.id === documentoId).ergonomia_cadastrada ===
                          "undefined" ||
                        acesso.data.find((item) => item.id === documentoId).ergonomia_cadastrada
                      ) {
                        window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id);
                      } else {
                        if (dadosToken().tipo === "2") {
                          toast.info(alertFrase);
                        } else {
                          toast.info("Não foi cadastrado o inventário de ergonomia");
                        }
                      }
                    }
                  } else {
                    if (dadosToken().tipo === "2") {
                      toast.info(alertFrase);
                    } else {
                      toast.info("Não foi cadastrado o documento base");
                    }
                  }
                  break;
              }
            } else if (documentoId === "4") {
              switch (acesso.data.find((item) => item.id === documentoId).ppra_id) {
                case "1184":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD + "Polo Films - Sede - Inventario - Risco Acidente - rev00.pdf"
                  );
                  break;

                case "1311":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Polo Films - Sede - Inventário - Risco Acidente - rev00a.pdf"
                  );
                  break;

                case "1259":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "Prolec Ge - Sede - PGR Inventário - Risco Acidente - rev00a.pdf"
                  );
                  break;

                case "1247":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Nexteer - Sede - Inventário - Risco Acidente - rev00.pdf"
                  );
                  break;

                case "1304":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Medicone - Sede - Inventário - Risco Acidente - rev00.pdf"
                  );
                  break;

                case "1392":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Medicone - Sede - Inventário - Risco Acidente - rev00a.pdf"
                  );
                  break;

                case "1326":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Prolec Ge - Sede - Inventário - Risco Acidente - rev00b.pdf"
                  );
                  break;

                case "1306":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Ball - Sede - Inventário - Risco Acidente - rev00a.pdf"
                  );
                  break;
                case "1273":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Trevo - Sede - Inventário - Risco Acidente - rev00.pdf"
                  );
                  break;

                case "1275":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Restinga - Sede - Inventário - Risco Acidente - rev00.pdf"
                  );
                  break;

                case "1362":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Restinga - Sede - Inventário - Risco Acidente - rev00a.pdf"
                  );
                  break;

                case "1321":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Polo Films - Sede - Inventário - Risco Acidente - rev00b.pdf"
                  );
                  break;

                case "1376":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Polo Films - Sede - Inventário - Risco Acidente - rev01.pdf"
                  );
                  break;

                case "1463":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Polo Films - Sede - Inventário - Risco Acidente - rev01a.pdf"
                  );
                  break;

                case "1377":
                  window.open(process.env.REACT_APP_CAMINHO_UPLOAD + "Inventario Acidentes - RDA - rev01.pdf");
                  break;

                case "1339":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Nexteer - Sede - Inventário - Risco Acidente - rev00a.pdf"
                  );
                  break;

                case "1300":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Solae - Sede - Inventário - Risco Acidente - rev00.pdf"
                  );
                  break;

                case "1458":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Prolec Ge - Sede - Inventário - Risco Acidente - rev01.pdf"
                  );
                  break;

                case "1442":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Prolec Ge - Sede - Inventário - Risco Acidente - rev01a.pdf"
                  );
                  break;

                case "1494":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Restinga - Sede - Inventário - Risco Acidente - rev00b.pdf"
                  );
                  break;

                case "1493":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Trevo - Sede - Inventário - Risco Acidente - rev00a.pdf"
                  );
                  break;

                case "1479":
                  window.open(
                    process.env.REACT_APP_CAMINHO_UPLOAD +
                      "1.3 - Hospital Sao Lucas - Pucrs - Sede - Inventário - Risco Acidente - rev00.pdf"
                  );
                  break;
                  
                default:
                  if (resposta.data.ultimo_documento_base) {
                    if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                      if (dadosToken().tipo === "2") {
                        toast.info(alertFrase);
                      } else {
                        toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                      }
                    } else {
                      if (
                        typeof acesso.data.find((item) => item.id === documentoId).acidente_cadastrada ===
                          "undefined" ||
                        acesso.data.find((item) => item.id === documentoId).acidente_cadastrada
                      ) {
                        window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id);
                      } else {
                        if (dadosToken().tipo === "2") {
                          toast.info(alertFrase);
                        } else {
                          toast.info("Não foi cadastrado o inventário de acidente");
                        }
                      }
                    }
                  } else {
                    if (dadosToken().tipo === "2") {
                      toast.info(alertFrase);
                    } else {
                      toast.info("Não foi cadastrado o documento base");
                    }
                  }
                  break;
              }
            }
            // }else if (documentoId === "7") {
          } else if (documentoId === "8") {
            switch (acesso.data.find((item) => item.id === documentoId).ppra_id) {
              case "1259":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "Prolec Ge - Sede - PGR Perfil da Função - rev00a.pdf"
                );
                break;

              case "1262":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Springer Carrier - Sede - Perfil da Função - rev00.pdf"
                );
                break;

              case "1445":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Springer Carrier - Sede - Perfil da Função - rev01.pdf"
                );
                break;

              case "1326":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Prolec Ge - Sede - Perfil da Função - rev00b.pdf"
                );
                break;

              case "1339":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Nexteer - Sede - Perfil da Função - rev00a.pdf"
                );
                break;

              case "1376":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Polo Films - Sede - Perfil da Função - rev01.pdf"
                );
                break;

              case "1463":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Polo Films - Sede - Perfil da Função - rev01a.pdf"
                );
                break;

              case "1458":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Prolec Ge - Sede - Perfil da Função - rev01.pdf"
                );
                break;

              case "1442":
                window.open(
                  process.env.REACT_APP_CAMINHO_UPLOAD + "3 - Prolec Ge - Sede - Perfil da Função - rev01a.pdf"
                );
                break;

              default:
                if (resposta.data.ultimo_documento_base) {
                  if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                    if (dadosToken().tipo === "2") {
                      toast.info(alertFrase);
                    } else {
                      toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                    }
                  } else {
                    window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id);
                  }
                } else {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Não foi cadastrado o documento base");
                  }
                }
                break;
            }
          } else if (documentoId === "1") {
            if (resposta.data.ultimo_documento_base) {
              if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                if (dadosToken().tipo === "2") {
                  toast.info(alertFrase);
                } else {
                  toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                }
              } else {
                window.open(
                  urlPadrao +
                    resposta.data.ultimo_documento_base.id +
                    "?qtd" +
                    resposta.data.ultimo_documento_base.quantidade_revisoes
                );
              }
            } else {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Não foi cadastrado o documento base");
              }
            }
          } else if (documentoId === "6" || documentoId === "23" || documentoId === "20") {
            if (resposta.data.ultimo_documento_base) {
              if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                if (dadosToken().tipo === "2") {
                  toast.info(alertFrase);
                } else {
                  toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                }
              } else {
                if (
                  typeof acesso.data.find((item) => item.id === "3").ergonomia_cadastrada === "undefined" ||
                  acesso.data.find((item) => item.id === "3").ergonomia_cadastrada
                ) {
                  if (documentoId === "20") {
                    window.open(
                      urlPadrao +
                        `${resposta.data.codigo}/` +
                        acesso.data.find((item) => item.id === documentoId).ppra_id
                    );
                  } else if (documentoId === "23") {
                    switch (acesso.data.find((item) => item.id === documentoId).ppra_id) {
                      case "1479":
                        window.open(
                          process.env.REACT_APP_CAMINHO_UPLOAD + "12.2 - Hospital Sao Lucas - Pucrs - Sede - GHE - Ergonomia - rev00.pdf"
                        );
                        break;
                    
                      default:
                        window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id)
                        break;
                    }
                  } else {
                    window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id);
                  }
                } else {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Não foi cadastrado o inventário de ergonomia");
                  }
                }
              }
            } else {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Não foi cadastrado o documento base");
              }
            }
          } else if (documentoId === "7" || documentoId === "24" || documentoId === "21") {
            if (resposta.data.ultimo_documento_base) {
              if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                if (dadosToken().tipo === "2") {
                  toast.info(alertFrase);
                } else {
                  toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                }
              } else {
                if (
                  typeof acesso.data.find((item) => item.id === "4").acidente_cadastrada === "undefined" ||
                  acesso.data.find((item) => item.id === "4").acidente_cadastrada
                ) {
                  if (documentoId === "21") {
                    window.open(
                      urlPadrao +
                        `${resposta.data.codigo}/` +
                        acesso.data.find((item) => item.id === documentoId).ppra_id
                    );
                  } else {
                    window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id);
                  }
                } else {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Não foi cadastrado o inventário de acidente");
                  }
                }
              }
            } else {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Não foi cadastrado o documento base");
              }
            }
          } else if (documentoId === "12") {
            if (resposta.data.ultimo_documento_base) {
              if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                if (dadosToken().tipo === "2") {
                  toast.info(alertFrase);
                } else {
                  toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                }
              } else {
                api
                  .get(
                    `/gro/pgr/checklist/verificarChecklistRevisao/${
                      acesso.data.find((item) => item.id === documentoId).ppra_id
                    }`
                  )
                  .then((resposta) => {
                    if (resposta.data) {
                      window.open(urlPadrao + `${acesso.data.find((item) => item.id === documentoId).ppra_id}`);
                    } else {
                      if (dadosToken().tipo === "2") {
                        toast.info(alertFrase);
                      } else {
                        toast.info("Nenhuma checklist cadastrada.");
                      }
                    }
                  });
              }
            } else {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Não foi cadastrado o documento base");
              }
            }
          } else {
            if (resposta.data.ultimo_documento_base) {
              if (isEmpty(documentoId)) {
                if (isEmpty(acesso.data.find((item) => item.id === "1").ppra_id)) {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                  }
                } else {
                  window.open(urlPadrao + acesso.data.find((item) => item.id === "1").ppra_id);
                }
              } else {
                if (isEmpty(acesso.data.find((item) => item.id === documentoId).ppra_id)) {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                  }
                } else {
                  if (modulo === "ppra") {
                    window.open(
                      urlPadrao +
                        resposta.data.codigo +
                        "/" +
                        acesso.data.find((item) => item.id === documentoId).ppra_id
                    );
                  } else if (modulo === "clienteId") {
                    window.open(urlPadrao + acesso.data.find((item) => item.id === documentoId).ppra_id);
                  } else if (modulo === "funcoesColaboradores") {
                    api
                      .get(
                        `clientes/obterFuncoesColaboradores?ppra_id=${
                          acesso.data.find((item) => item.id === documentoId).ppra_id
                        }`
                      )
                      .then((resposta) => {
                        if (isEmpty(resposta.data[0])) {
                          if (dadosToken().tipo === "2") {
                            return toast.info(alertFrase);
                          } else {
                            toast.info("Responsável que forneceu a lista não esta cadastrado");
                          }
                        } else {
                          return window.open(
                            urlPadrao +
                              clienteEstabelecimentoId +
                              "/" +
                              acesso.data.find((item) => item.id === documentoId).ppra_id,
                            "_blank"
                          );
                        }
                      });
                  }
                }
              }
            } else {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Não foi cadastrado o documento base");
              }
            }
          }
        });
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  const urlOperacionalCliente =
    dadosToken() && dadosToken().cliente_id === "107"
      ? `${process.env.REACT_APP_ERGOSEG_URL}/operacional/index.php/Logar/54/sulzer/u/SULZER`
      : dadosToken().usuario_operacional
      ? `${process.env.REACT_APP_ERGOSEG_URL}/operacional/index.php/Logar/${
          dadosToken().usuario_operacional.usuario_id
        }/${dadosToken().usuario_operacional.login}/${dadosToken().usuario_operacional.tipo}/${
          dadosToken().usuario_operacional.usuario
        }`
      : null;

  function isUpload(clienteId, documento, tipo) {
    api.get(`clientes/obterUploadPDF/${clienteId}?documento=${documento}&tipo=${tipo}`).then((resposta) => {
      if (resposta.data.length > 0) {
        if (tipo === "1") {
          if (documento === "EPCs_X_Ambientes") {
            setShowModalUploadPDF(true);
            setBotao("EPCs X Ambientes");
            setErgonomia(true);
            setArquivoNome("EPCs_X_Ambientes");
          } else if (documento === "GHE") {
            setShowModalUploadPDF(true);
            setBotao("GHE");
            setErgonomia(true);
            setArquivoNome("GHE");
          } else if (documento === "plano_de_acao") {
            setShowModalUploadPDF(true);
            setBotao("Plano de Ação");
            setErgonomia(true);
            setArquivoNome("plano_de_acao");
          }
        } else {
          if (documento === "EPCs_X_Ambientes") {
            setShowModalUploadPDF(true);
            setBotao("EPCs X Ambientes");
            setErgonomia(false);
            setArquivoNome("EPCs_X_Ambientes");
          } else if (documento === "GHE") {
            setShowModalUploadPDF(true);
            setBotao("GHE");
            setErgonomia(false);
            setArquivoNome("GHE");
          } else if (documento === "plano_de_acao") {
            setShowModalUploadPDF(true);
            setBotao("Plano de Ação");
            setErgonomia(false);
            setArquivoNome("plano_de_acao");
          }
        }
      } else {
        if (tipo === "1") {
          if (documento === "EPCs_X_Ambientes") {
            abrirModal("epc/epcsErgonomia/gerar/", "ppra", "20");
          } else if (documento === "GHE") {
            abrirModal("gheErgonomia/gerar/", "clienteId", "23");
          } else if (documento === "plano_de_acao") {
            // abrirModal("PlanoAcaoErgonomia/gerar/", "clienteId", "6");
            setShowPlanoAcao(true);
          }
        } else {
          if (documento === "EPCs_X_Ambientes") {
            abrirModal("epc/epcsAcidente/gerar/", "ppra", "21");
          } else if (documento === "GHE") {
            abrirModal("gheAcidente/gerar/", "clienteId", "24");
          } else if (documento === "plano_de_acao") {
            // abrirModal("PlanoAcaoAcidente/gerar/", "clienteId", "7");
            setShowPlanoAcao(true);
          }
        }
      }
    });
  }

  const ModalPlanoAcao = ({ show, setShow, documentoId }) => {
    const [cliente, setCliente] = useState();
    const [carregando, setCarregando] = useState(true);
    const [carregandoEstabelecimento, setCarregandoEstabelecimento] = useState();
    const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState();
    const [revisoes, setRevisoes] = useState([]);

    let titulo = documentoId === "5" ? "Físico, Químico e Biológico" : documentoId === "6" ? "Ergonomia" : "Acidente";

    const { clienteId } = useParams();

    useEffect(() => {
      api.get(`clientes/obterClienteComPGR/${clienteId}`).then((resposta) => {
        setCliente(resposta.data);
        if (resposta.data.estabelecimentos.length > 1) {
          setCarregando(false);
        } else {
          api
            .get(
              `clientes/obterRevisoesPlanoAcao/${clienteId}/${resposta.data.estabelecimentos[0].cod_estabelecimento}/${documentoId}`
            )
            .then((revisoes) => {
              setRevisoes(revisoes.data);
              setCarregando(false);
            });
        }
      });
    }, [clienteId, documentoId]);

    const revisoesLiberadas = revisoes?.filter((filtro) => filtro.liberado);

    const optionsEstabelecimentos = cliente?.estabelecimentos?.map((item) => ({
      label: item.nome_generico,
      value: item,
    }));

    function selecionaEstabelecimento(estabelecimento) {
      setEstabelecimentoSelecionado(estabelecimento);
      api
        .get(`clientes/obterRevisoesPlanoAcao/${clienteId}/${estabelecimento.cod_estabelecimento}/${documentoId}`)
        .then((resposta) => {
          setRevisoes(resposta.data);
          setCarregandoEstabelecimento(false);
        });
    }

    function gerarPDF(clienteEstabelecimentoId, modulo) {
      api.get(`clientes/obterAcessoPDF/${clienteEstabelecimentoId}`).then((resposta) => {
        if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
          if (dadosToken().tipo === "2") {
            toast.info("Documento em produção!");
          } else {
            toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
          }
        } else {
          if (modulo === "gerar") {
            if (documentoId === "5") {
              window.open(
                process.env.REACT_APP_API_URL +
                  `/gro/pgr/pdf/cronogramas/${modulo}/${clienteEstabelecimentoId}/${
                    resposta.data.find((item) => item.id === "5").ppra_id
                  }`
              );
            } else if (documentoId === "6") {
              window.open(
                process.env.REACT_APP_API_URL +
                  `/gro/pgr/pdf/PlanoAcaoErgonomia/gerar/${resposta.data.find((item) => item.id === "6").ppra_id}`
              );
            } else if (documentoId === "7") {
              window.open(
                process.env.REACT_APP_API_URL +
                  `/gro/pgr/pdf/PlanoAcaoAcidente/gerar/${resposta.data.find((item) => item.id === "7").ppra_id}`
              );
            }
          } else if (modulo === "gerarRealizados") {
            if (documentoId === "5") {
              window.open(
                process.env.REACT_APP_API_URL +
                  `/gro/pgr/pdf/cronogramas/${modulo}/${resposta.data.find((item) => item.id === "5").ppra_id}`
              );
            } else if (documentoId === "6") {
              window.open(
                process.env.REACT_APP_API_URL +
                  `/gro/pgr/pdf/PlanoAcaoErgonomia/gerarRealizado/${
                    resposta.data.find((item) => item.id === "6").ppra_id
                  }`
              );
            } else if (documentoId === "7") {
              window.open(
                process.env.REACT_APP_API_URL +
                  `/gro/pgr/pdf/PlanoAcaoAcidente/gerarRealizado/${
                    resposta.data.find((item) => item.id === "7").ppra_id
                  }`
              );
            }
          }

          //Fecha modal assim que abrir o PDF
          setShow(false);
        }
      });
    }

    function gerarPDFppraId(ppraId) {
      if (documentoId === "5") {
        window.open(process.env.REACT_APP_API_URL + `/gro/pgr/pdf/cronogramas/gerarRealizados/${ppraId}`);
      } else if (documentoId === "6") {
        window.open(process.env.REACT_APP_API_URL + `/gro/pgr/pdf/PlanoAcaoErgonomia/gerarRealizado/${ppraId}`);
      } else if (documentoId === "7") {
        window.open(process.env.REACT_APP_API_URL + `/gro/pgr/pdf/PlanoAcaoAcidente/gerarRealizado/${ppraId}`);
      }

      //Fecha modal assim que abrir o PDF
      setShow(false);
    }

    return (
      <ModalPadrao tamanho="md" setShow={setShow} show={show} titulo="Plano de ação" tituloSpan={titulo}>
        {carregando ? (
          <Loader />
        ) : (
          <>
            {cliente.estabelecimentos?.length > 1 ? (
              <>
                <div className="row">
                  <div className="col">
                    <Select
                      options={optionsEstabelecimentos}
                      onChange={(e) => {
                        setCarregandoEstabelecimento(true);
                        selecionaEstabelecimento(e.value);
                      }}
                    />
                  </div>
                </div>

                {!isEmpty(estabelecimentoSelecionado) && (
                  <>
                    {isEmpty(estabelecimentoSelecionado.documento_base_id) && (
                      <div className="row mt-4">
                        <div className="col">
                          {dadosToken().tipo === "2" ? "Documento em produção" : "Necessita gerar documento base"}
                        </div>
                      </div>
                    )}

                    <div className="row mt-5">
                      <div className="col">
                        {carregandoEstabelecimento ? (
                          <Loader />
                        ) : (
                          <>
                            <div className="col">
                              <div className="btn-group-vertical w-100 h-150">
                                <div className="btn-group h-50">
                                  <Botao
                                    className="w-100"
                                    conteudo="Plano de ação"
                                    icone="print"
                                    cor="cinza"
                                    onClick={() => {
                                      if (isEmpty(estabelecimentoSelecionado.documento_base_id)) {
                                        toast.warning(
                                          dadosToken().tipo === "2"
                                            ? "Documento em produção"
                                            : "Não existe documento base cadastrado!"
                                        );
                                      } else {
                                        gerarPDF(estabelecimentoSelecionado.codigo, "gerar");
                                      }
                                    }}
                                    outline
                                    disabled={isEmpty(estabelecimentoSelecionado.documento_base_id)}
                                  />

                                  <Botao
                                    className="w-100"
                                    conteudo="Realizados"
                                    icone="print"
                                    cor="cinza"
                                    onClick={() => {
                                      if (isEmpty(estabelecimentoSelecionado.documento_base_id)) {
                                        toast.warning(
                                          dadosToken().tipo === "2"
                                            ? "Documento em produção"
                                            : "Não existe documento base cadastrado!"
                                        );
                                      } else {
                                        gerarPDF(estabelecimentoSelecionado.codigo, "gerarRealizados");
                                      }
                                    }}
                                    outline
                                    disabled={isEmpty(estabelecimentoSelecionado.documento_base_id)}
                                  />
                                </div>
                              </div>
                            </div>

                            {revisoesLiberadas.length > 1 && (
                              <>
                                <div className="row mt-4">
                                  <div className="col">
                                    <TabelaPadrao
                                      noOverflowAuto
                                      itens={revisoesLiberadas}
                                      colunas={[
                                        { nome: "Revisoes anteriores - Realizados" },
                                        { nome: "PDF", width: "12%", text: "center" },
                                      ]}
                                    >
                                      {revisoesLiberadas.map((item, index) => (
                                        <>
                                          {index !== 0 && (
                                            <tr>
                                              <td>
                                                <strong>Revisão: </strong>
                                                {item.revisao}
                                              </td>
                                              <td className="text-center">
                                                <Botao
                                                  icone="print"
                                                  cor="secondary"
                                                  onClick={() => gerarPDFppraId(item.id)}
                                                />
                                              </td>
                                            </tr>
                                          )}
                                        </>
                                      ))}
                                    </TabelaPadrao>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {isEmpty(cliente.estabelecimentos[0].documento_base_id) ? (
                  <>{dadosToken().tipo === "2" ? <>PDF em produção</> : <>Necessita da criação do documento base</>}</>
                ) : (
                  <>
                    <div className="col">
                      <div className="btn-group-vertical w-100 h-150">
                        <div className="btn-group h-50">
                          <Botao
                            className="w-100"
                            conteudo="Plano de ação"
                            icone="print"
                            cor="cinza"
                            onClick={() => {
                              if (isEmpty(cliente.estabelecimentos[0].documento_base_id)) {
                                toast.warning(
                                  dadosToken().tipo === "2"
                                    ? "Documento em produção"
                                    : "Não existe documento base cadastrado!"
                                );
                              } else {
                                gerarPDF(cliente.estabelecimentos[0].codigo, "gerar");
                              }
                            }}
                            outline
                          />

                          <Botao
                            className="w-100"
                            conteudo="Realizados"
                            icone="print"
                            cor="cinza"
                            onClick={() => {
                              if (isEmpty(cliente.estabelecimentos[0].documento_base_id)) {
                                toast.warning(
                                  dadosToken().tipo === "2"
                                    ? "Documento em produção"
                                    : "Não existe documento base cadastrado!"
                                );
                              } else {
                                gerarPDF(cliente.estabelecimentos[0].codigo, "gerarRealizados");
                              }
                            }}
                            outline
                          />
                        </div>
                      </div>
                    </div>

                    {revisoesLiberadas.length > 1 && (
                      <>
                        <div className="row mt-4">
                          <div className="col">
                            <TabelaPadrao
                              noOverflowAuto
                              itens={revisoesLiberadas}
                              colunas={[
                                { nome: "Revisoes anteriores - Realizados" },
                                { nome: "PDF", width: "12%", text: "center" },
                              ]}
                            >
                              {revisoesLiberadas.map((item, index) => (
                                <>
                                  {index !== 0 && (
                                    <tr>
                                      <td>
                                        <strong>Revisão: </strong>
                                        {item.revisao}
                                      </td>
                                      <td className="text-center">
                                        <Botao icone="print" cor="secondary" onClick={() => gerarPDFppraId(item.id)} />
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ))}
                            </TabelaPadrao>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </ModalPadrao>
    );
  };

  return (
    <>
      <Template
        page="PGR"
        backTo={dadosToken().tipo <= "1" ? `/gro/seleciona-cliente/cliente/${clienteId}` : VerificaBackTo(clienteId)}
        headerGro
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="container-fluid">
              <div className={!isLaurence() ? "bg-white p-3 pb-5 pt-2" : "bg-white p-2 pb-5"}>
                <ToastContainer autoClose={5000} pauseOnHover={false} />
                <div className="row">
                  <div className="col">
                    <div className="card bg-cinza text-white">
                      <span className="align-middle">
                        <h4 className="text-center">
                          Programa de Gerenciamento de Riscos (<strong>PGR</strong>)
                        </h4>
                      </span>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="row mt-1" style={{ height: "73px" }}>
                          <div className="col">
                            <div className="btn-group-vertical w-100 h-100">
                              <div className="btn-group btn-group h-64">
                                <Botao
                                  className="w-100 d-flex justify-content-center align-items-center"
                                  conteudo={<h6>Documento Base (0)</h6>}
                                  cor="cinza"
                                  onClick={() => {
                                    setDocumentoId("1");
                                    dadosToken().tipo === "2" && abrirModal("documentoBase/gerar/", "docBase", "1");
                                  }}
                                  alvo={dadosToken().tipo !== "2" && "pgr/documento-base#1"}
                                  // alvo={dadosToken().tipo !== "2" && `pgr/documento-base/${estabelecimentos?.find((find) => find.tipo === "S" || find.tipo === "SE")?.codigo}`}
                                  // router={dadosToken().tipo !== "2"}
                                  target
                                  outline
                                />
                                <Botao
                                  style={{ cursor: "default" }}
                                  conteudo={
                                    <h6>
                                      Inventario de Risco (1)
                                      <br />
                                      <div style={{ cursor: "pointer" }}>
                                        <SpanBadge
                                          className="mt-1"
                                          texto="Físico, Químico, Biológico"
                                          cor="secondary"
                                          onClick={() => {
                                            if (acessoDocumento?.find((item) => item.id === "4").status === "I") {
                                              toast.info("Inventário em Produção");
                                            } else {
                                              setDocumentoId("2");
                                              abrirModal("quadros/gerarRevisao/", "ppra", "2");
                                            }
                                          }}
                                        />
                                        <SpanBadge
                                          className="mt-1 mx-2"
                                          texto="Ergonomia"
                                          cor="secondary"
                                          onClick={() => {
                                            if (acessoDocumento.find((item) => item.id === "5").status === "I") {
                                              toast.info("Inventário em Produção");
                                            } else {
                                              setDocumentoId("3");
                                              abrirModal("apres/gerarRevisao/", "ppra", "3");
                                            }
                                          }}
                                        />
                                        <SpanBadge
                                          texto="Acidente"
                                          cor="secondary"
                                          onClick={() => {
                                            if (acessoDocumento.find((item) => item.id === "6").status === "I") {
                                              toast.info("Inventário em Produção");
                                            } else {
                                              setDocumentoId("4");
                                              abrirModal("acidente/gerarRevisao/", "ppra", "4");
                                            }
                                          }}
                                        />
                                      </div>
                                    </h6>
                                  }
                                  className="w-100  d-flex justify-content-center align-items-center"
                                  cor="cinza"
                                  outline
                                />
                              </div>
                              <div className="btn-group btn-group h-50">
                                <Botao
                                  style={{ cursor: "default" }}
                                  conteudo={
                                    <h6>
                                      Plano de Ação (2)
                                      <br />
                                      <div style={{ cursor: "pointer" }}>
                                        <SpanBadge
                                          texto={"Físico, Químico, Biológico"}
                                          cor="secondary"
                                          onClick={() => {
                                            if (acessoDocumento?.find((item) => item.id === "3").status === "I") {
                                              toast.info("Plano de Ação em produção");
                                            } else {
                                              setDocumentoId("5");
                                              // abrirModal("cronogramas/gerar/", "ppra", "5");
                                              setShowPlanoAcao(true);
                                            }
                                          }}
                                        />
                                        {showPlanoAcao && (
                                          <ModalPlanoAcao
                                            show={showPlanoAcao}
                                            setShow={setShowPlanoAcao}
                                            documentoId={documentoId}
                                            setDocumentoId={setDocumentoId}
                                            abrirModal={abrirModal}
                                          />
                                        )}
                                        <SpanBadge
                                          className="mt-1 mx-2"
                                          texto="Ergonomia"
                                          cor="secondary"
                                          onClick={() => {
                                            if (acessoDocumento.find((item) => item.id === "2").status === "I") {
                                              toast.info("Plano de Ação em Produção");
                                            } else {
                                              setDocumentoId("6");
                                              // setShowPlanoAcao(true);
                                              isUpload(clienteId, "plano_de_acao", "1");
                                            }
                                          }}
                                        />
                                        {showModalUploadPDF && (
                                          <UploadPDF
                                            setShow={setShowModalUploadPDF}
                                            show={showModalUploadPDF}
                                            clienteId={clienteId}
                                            dadosToken={dadosToken()}
                                            botao={botao}
                                            ergonomia={ergonomia}
                                            arquivoNome={arquivoNome}
                                          />
                                        )}
                                        <SpanBadge
                                          texto="Acidente"
                                          cor="secondary"
                                          onClick={() => {
                                            if (acessoDocumento.find((item) => item.id === "1").status === "I") {
                                              toast.info("Plano de Ação em Produção");
                                            } else {
                                              setDocumentoId("7");
                                              // setShowPlanoAcao(true);
                                              isUpload(clienteId, "plano_de_acao", "0");
                                              // abrirModal("PlanoAcaoAcidente/gerar/", "clienteId", "7");
                                            }
                                          }}
                                        />
                                      </div>
                                    </h6>
                                  }
                                  className="w-100  d-flex justify-content-center align-items-center"
                                  cor="cinza"
                                  outline
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col mt-4">
                    <div className="card bg-cinza text-white mt-3">
                      <span className="align-middle">
                        <h4 className="text-center">Reconhecimento dos Riscos</h4>
                      </span>
                    </div>
                    <div className="row mt-1" style={{ height: "120px" }}>
                      <div className="col">
                        <div className="btn-group-vertical w-100 h-100">
                          <div className="btn-group btn-group h-50">
                            <Botao
                              conteudo="Perfil da Função (3)"
                              className="w-100"
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "13").status === "I") {
                                  toast.info("Perfil da Função em produção");
                                } else {
                                  setDocumentoId("8");
                                  abrirModal("perfisFuncoes/gerar/", "clienteId", "8");
                                }
                              }}
                              target
                              outline
                            />
                            <Botao
                              conteudo={<span>Funções X Colaboradores (4)</span>}
                              className="w-100 "
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "14").status === "I") {
                                  toast.info("Funções X Colaboradores em Produção");
                                } else {
                                  setDocumentoId("9");
                                  abrirModal("funcoesFuncionarios/gerar/", "funcoesColaboradores", "9");
                                }
                              }}
                              outline
                            />
                            <Botao
                              conteudo="Funções X EPI's (5)"
                              className="w-100"
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "15").status === "I") {
                                  toast.info("Funções X EPI's em Produção");
                                } else {
                                  setDocumentoId("10");
                                  abrirModal("funcoesEpis/gerar/", "ppra", "10");
                                }
                              }}
                              target
                              outline
                            />
                            <Botao
                              conteudo="Atividades Especiais (6)"
                              className="w-100"
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "16").status === "I") {
                                  toast.info("Atividades Especiais em Produção");
                                } else {
                                  setDocumentoId("11");
                                  abrirModal("atividadesEspeciais/gerar/", "ppra", "11");
                                }
                              }}
                              target
                              outline
                            />
                            <Botao
                              conteudo="Setores X Ambientes (7)"
                              className="w-100"
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "18").status === "I") {
                                  toast.info("Setores X Ambientes em Produção");
                                } else {
                                  setDocumentoId("13");
                                  abrirModal("quadros/gerarIndice/", "clienteId", "13");
                                }
                              }}
                              outline
                            />
                            <Botao
                              conteudo="Produtos Químicos (8)"
                              className="w-100"
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "19").status === "I") {
                                  toast.info("Produtos Químicos em Produção");
                                } else {
                                  setDocumentoId("14");
                                  abrirModal("produtosQuimicos/gerar/", "ppra", "14");
                                }
                              }}
                              target
                              outline
                            />
                            <Botao
                              conteudo="LINACH (cancerígenos) (9)"
                              className="w-100"
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "20").status === "I") {
                                  toast.info("LINACH (cancerígenos) em Produção");
                                } else {
                                  setDocumentoId("15");
                                  abrirModal("linachs/gerar/", "ppra", "15");
                                }
                              }}
                              outline
                            />
                            <Botao
                              conteudo="Fotos Ambientes (10)"
                              className="w-100"
                              cor="cinza"
                              onClick={() => {
                                if (acessoDocumento.find((item) => item.id === "21").status === "I") {
                                  toast.info("Fotos Ambientes em Produção");
                                } else {
                                  setDocumentoId("16");
                                  abrirModal("fotosAmbientes/gerar/", "ppra", "16");
                                }
                              }}
                              target
                              outline
                            />
                          </div>
                          <div className="btn-group btn-group h-50">
                            <Botao
                              style={{ cursor: "default" }}
                              conteudo={
                                <h6>
                                  EPC's X Ambientes (11)
                                  <br />
                                  <div style={{ cursor: "pointer" }}>
                                    <SpanBadge
                                      texto="Físico, Químico, Biológico"
                                      cor="secondary"
                                      onClick={() => {
                                        if (acessoDocumento.find((item) => item.id === "12").status === "I") {
                                          toast.info("EPC's em Produção");
                                        } else {
                                          setDocumentoId("19");
                                          abrirModal("epcs/gerar/", "ppra", "19");
                                        }
                                      }}
                                    />
                                    <SpanBadge
                                      className="mt-1 mx-2"
                                      texto="Ergonomia"
                                      cor="secondary"
                                      onClick={() => {
                                        if (acessoDocumento.find((item) => item.id === "11").status === "I") {
                                          toast.info("EPC's em Produção");
                                        } else {
                                          setDocumentoId("20");
                                          isUpload(clienteId, "EPCs_X_Ambientes", "1");
                                        }
                                      }}
                                    />
                                    <SpanBadge
                                      texto="Acidente"
                                      cor="secondary"
                                      onClick={() => {
                                        if (acessoDocumento.find((item) => item.id === "10").status === "I") {
                                          toast.info("EPC's em Produção");
                                        } else {
                                          setDocumentoId("21");
                                          isUpload(clienteId, "EPCs_X_Ambientes", "0");
                                        }
                                      }}
                                    />
                                  </div>
                                </h6>
                              }
                              className="w-100  d-flex justify-content-center align-items-center"
                              cor="cinza"
                              outline
                            />
                            <Botao
                              style={{ cursor: "pointer" }}
                              conteudo={
                                <h6>
                                  GHE (12)
                                  <br />
                                  <div style={{ cursor: "pointer" }}>
                                    <SpanBadge
                                      texto="Físico e Químico"
                                      cor="secondary"
                                      onClick={() => {
                                        if (acessoDocumento.find((item) => item.id === "9").status === "I") {
                                          toast.info("GHE em Produção");
                                        } else {
                                          setDocumentoId("22");
                                          abrirModal("ghes/gerarGHE/", "clienteId", "22");
                                        }
                                      }}
                                    />
                                    <SpanBadge
                                      className="mt-1 mx-2"
                                      texto="Ergonomia"
                                      cor="secondary"
                                      onClick={() => {
                                        if (acessoDocumento.find((item) => item.id === "7").status === "I") {
                                          toast.info("GHE em Produção");
                                        } else {
                                          setDocumentoId("23");
                                          isUpload(clienteId, "GHE", "1");
                                        }
                                      }}
                                    />
                                    <SpanBadge
                                      texto="Acidente"
                                      cor="secondary"
                                      onClick={() => {
                                        if (documentos.find((item) => item.id === "24").status === "I") {
                                          toast.info("GHE em Produção");
                                        } else {
                                          setDocumentoId("24");
                                          isUpload(clienteId, "GHE", "0");
                                        }
                                      }}
                                    />
                                  </div>
                                </h6>
                              }
                              className="w-100  d-flex justify-content-center align-items-center"
                              cor="cinza"
                              outline
                            />

                            <Botao
                              style={{ cursor: "pointer" }}
                              conteudo={
                                <h6>
                                  Tabelas de perigos (13)
                                  <br />
                                  <div style={{ cursor: "pointer" }}>
                                    <SpanBadge
                                      texto="Físico, Químico, Biológico"
                                      cor="secondary"
                                      onClick={() => {
                                        if (acessoDocumento.find((item) => item.id === "1").status === "I") {
                                          toast.info("Documento base em Produção");
                                        } else {
                                          setDocumentoId(null);
                                          abrirModal(
                                            "tabelasPerigos/PerigosFisicoQuimicoBiologicos/gerarRevisao/",
                                            "ppra"
                                          );
                                        }
                                      }}
                                    />
                                    <SpanBadge
                                      className="mt-1 mx-2"
                                      texto="Ergonomia"
                                      cor="secondary"
                                      onClick={() => {
                                        if (acessoDocumento.find((item) => item.id === "1").status === "I") {
                                          toast.info("Documento base em Produção");
                                        } else {
                                          setDocumentoId(null);
                                          abrirModal("tabelasPerigos/PerigosErgonomia/gerarRevisao/", "ppra");
                                        }
                                      }}
                                    />
                                    <SpanBadge
                                      texto="Acidente"
                                      cor="secondary"
                                      onClick={() => {
                                        if (documentos.find((item) => item.id === "1").status === "I") {
                                          toast.info("Documento base em Produção");
                                        } else {
                                          setDocumentoId(null);
                                          abrirModal("tabelasPerigos/PerigosAcidente/gerarRevisao/", "ppra");
                                        }
                                      }}
                                    />
                                  </div>
                                </h6>
                              }
                              className="w-100  d-flex justify-content-center align-items-center"
                              cor="cinza"
                              outline
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col">
                    <div className="card bg-cinza text-white">
                      <span className="align-middle">
                        <h4 className="text-center">Outros</h4>
                      </span>
                    </div>
                    <div className="row mt-1" style={{ height: "70px" }}>
                      <div className="col">
                        <div className="btn-group-vertical w-100 h-100">
                          <div className="btn-group btn-group h-100">
                            <div className="border-top btn-group btn-group w-100 h-100">
                              <Botao
                                conteudo={
                                  <>
                                    Checklist <br /> Geral (14)
                                  </>
                                }
                                className="w-100"
                                cor="cinza"
                                onClick={() => {
                                  if (acessoDocumento.find((item) => item.id === "17").status === "I") {
                                    toast.info("Checklist em Produção");
                                  } else {
                                    setDocumentoId("12");
                                    abrirModal("checklist/gerarRevisao/", "checklist", "12");
                                  }
                                }}
                                target
                                outline
                              />
                              <Botao
                                conteudo="ART (15)"
                                className="w-100"
                                cor="cinza"
                                onClick={() => setShowModalART(true)}
                                target
                                outline
                              />
                               <Botao
                                conteudo={<>Avaliação Psicossocial (Externa)</>}
                                className="w-100"
                                cor="cinza"
                                onClick={() => setShowMOdalAvaliacaoPsicossocial(true)}
                                target
                                outline
                              />
                              <Botao
                                conteudo={"Objetivos / Metas / Resultados"}
                                className="w-100 p-4"
                                cor="cinza"
                                alvo={dadosToken().tipo !== "2" && `objetivosMetas/`}
                                target
                                outline
                              />

                              {showModalART && (
                                <ModalART
                                  setShowModalART={setShowModalART}
                                  showModalART={showModalART}
                                  clienteId={clienteId}
                                  dadosToken={dadosToken()}
                                  estabelecimentos={estabelecimentos}
                                />
                              )}
                              <Botao
                                conteudo={
                                  <>
                                    Avaliações Ambientais <br />
                                    (Realizadas Segure)
                                  </>
                                }
                                className="w-100"
                                style={{ fontSize: "80%" }}
                                cor="cinza"
                                onClick={() => window.open(urlOperacionalCliente)}
                                disabled={!urlOperacionalCliente}
                                target
                                outline
                              />
                              <Botao
                                conteudo={
                                  <>
                                    PPR -<br />
                                    Ensaio de Vedação / <br />
                                    Teste Lab.
                                  </>
                                }
                                className="w-100 p-6"
                                cor="cinza"
                                alvo={"pgr/ppr#1"}
                                target
                                outline
                                disabled={dadosToken().tipo === "2" ? !existeEnsaios : false}
                              />
                              <Botao
                                conteudo={
                                  <>
                                    AET - Ergonomia <br /> (Previsão)
                                  </>
                                }
                                className="w-100"
                                cor="cinza"
                                onClick={() => setShowAET(true)}
                                target
                                outline
                              />
                              {showAET && <ModalAET show={showAET} setShow={setShowAET} />}
                              <Botao
                                conteudo={
                                  <>
                                    Histórico de Revisões <br /> (PGR)
                                  </>
                                }
                                className="w-100"
                                cor="cinza"
                                onClick={() =>
                                  // dadosToken().tipo === "2" ? toast.info("Em desenvolvimento") :
                                  window.open("pgr/historico-revisao", "_self")
                                }
                                outline
                              />
                              {showModalHistorico && (
                                <ModalHistoricoRevisoes show={showModalHistorico} setShow={setShowModalHistorico} />
                              )}
                              <Botao
                                conteudo={<>Documentos para PCMSO</>}
                                className="w-100"
                                cor="cinza"
                                onClick={() => setShowPCMSO(true)}
                                target
                                outline
                              />
                              <Botao
                                conteudo={<>Outros Documentos</>}
                                className="w-100"
                                cor="cinza"
                                onClick={() => setShowOutrosDocumentos(true)}
                                target
                                outline
                              />
                              <Botao
                                conteudo={<>Informativos</>}
                                className="w-100 fs-7"
                                cor="cinza"
                                onClick={() => {
                                  setShowInformativos(true);
                                }}
                                target
                                outline
                              />
                              {showPCMSO && (
                                <ModalPCMSO
                                  showModalPCMSO={showPCMSO}
                                  setShowModalPCMSO={setShowPCMSO}
                                  estabelecimentos={estabelecimentos}
                                  buscarDadosCliente={buscarDadosCliente}
                                />
                              )}
                              {showOutrosDocumentos && (
                                <ModalOutrosDocumentos
                                  show={showOutrosDocumentos}
                                  setShow={setShowOutrosDocumentos}
                                  estabelecimentos={estabelecimentos}
                                  buscarDadosCliente={buscarDadosCliente}
                                  clienteId={clienteId}
                                  dadosToken={dadosToken()}
                                />
                              )}
                              {showModalAvaiacaoPsicossocial && (
                                <ModalAvaliacaoPsicossocial 
                                  show={showModalAvaiacaoPsicossocial} 
                                  setShow={setShowMOdalAvaliacaoPsicossocial}
                                  estabelecimentos={estabelecimentos}
                                  clienteId={clienteId}
                                  dadosToken={dadosToken()}
                                />
                              )}
                              {showInformativos && (
                                <ModalInformativos show={showInformativos} setShow={setShowInformativos} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Template>
      {showModalEstab && (
        <SelecionaEstabelecimento
          show={showModalEstab}
          setShow={setShowModalEstab}
          clienteId={clienteId}
          estabelecimentos={estabelecimentos}
          buscarDadosCliente={buscarDadosCliente}
          dadosClienteEstab={dadosClienteEstab}
          url={url}
          isInventario={isInventario}
          isFuncoesColaboradores={isFuncoesColaboradores}
          modulo={modulo}
          documentoId={documentoId}
          setDadosClienteEstab={setDadosClienteEstab}
        />
      )}
    </>
  );
};

const SelecionaEstabelecimento = ({
  show,
  setShow,
  estabelecimentos,
  buscarDadosCliente,
  dadosClienteEstab,
  url,
  isInventario,
  isFuncoesColaboradores,
  modulo,
  documentoId,
  setDadosClienteEstab,
}) => {
  const [acessoPdf, setAcessoPdf] = useState();
  const [ppra, setPpra] = useState();
  const [carregando, setCarregando] = useState(false);

  const { clienteId } = useParams();

  useEffect(() => {
    api.get(`clientes/obterAcessoPDF/${dadosClienteEstab.codigo}`).then((resposta) => {
      setAcessoPdf(resposta.data);
    });
  }, [dadosClienteEstab.codigo]);

  let ppraRevisao = acessoPdf?.find((item) => item.id === documentoId);

  const gerarParametro = (url, value) => {
    let urls = url.split("/");
    if (urls.includes("documentoBase")) {
      return "?qtd=" + value;
    } else if (modulo === "clienteId") {
      return "ergonomia";
    }
    return "";
  };

  function buscarPpra(estabelecimentoId) {
    setCarregando(true);
    if (estabelecimentos.find((item) => item.cod_estabelecimento === estabelecimentoId).qtd_revisoes <= 0) {
      api
        .get("gro/pgr/painel", {
          params: {
            clienteEstabelecimentoId: estabelecimentos.find((item) => item.cod_estabelecimento === estabelecimentoId)
              .codigo,
          },
        })
        .then((resposta) => {
          setDadosClienteEstab(resposta.data);
          setCarregando(false);
        });
    } else {
      api
        .get(
          `clientes/obterPpraDocumento?cliente_id=${clienteId}&documentoId=${documentoId}&estabelecimento_id=${estabelecimentoId}`
        )
        .then((resposta) => {
          setPpra(resposta.data);
          api
            .get("gro/pgr/painel", {
              params: {
                clienteEstabelecimentoId: estabelecimentos.find(
                  (item) => item.cod_estabelecimento === estabelecimentoId
                ).codigo,
              },
            })
            .then((resposta) => {
              setDadosClienteEstab(resposta.data);
              setCarregando(false);
            });
        });
    }
  }

  return (
    <ModalPadrao show={show} setShow={setShow} tamanho="md" titulo="Escolha o estabelecimento">
      <>
        {!isEmpty(
          ppra?.documentos?.filter(
            (resposta) => (resposta?.status === "0" || resposta?.status === null) && ppra?.created_at >= "2023-07-24"
          )
        ) ? (
          <>
            <label className="mt-3">Estabelecimentos</label>
            <div className="row">
              <div className="col">
                <Select
                  options={estabelecimentos.map((cliEstab) => ({
                    label: cliEstab.estabelecimento.nome_generico,
                    value: cliEstab,
                  }))}
                  onChange={({ value }) => {
                    buscarPpra(value.cod_estabelecimento);
                  }}
                />
                <div className="text-danger">
                  <span>(Documento em produção) para visualizar documentos antigos acesse por</span>
                  <Link className="ml-1 text-decoration-none" to={`/gro/${clienteId}/pgr/historico-revisao`}>
                    Histórico de revisão (PGR)
                  </Link>
                </div>
                <footer>
                  <div className="row float-end mt-3">
                    <Botao cor="secondary" icone="print" conteudo="Gerar" disabled />
                  </div>
                </footer>
              </div>
            </div>
          </>
        ) : (
          <>
            <label className="mt-3">Estabelecimentos</label>
            <div className="row">
              <div className="col">
                <Select
                  options={estabelecimentos.map((cliEstab) => ({
                    label: cliEstab.estabelecimento.nome_generico,
                    value: cliEstab,
                  }))}
                  onChange={({ value }) => {
                    buscarPpra(value.cod_estabelecimento);
                  }}
                />
                <div className="row float-end mt-3">
                  <GerarBotao
                    url={url}
                    dadosClienteEstab={dadosClienteEstab}
                    params={gerarParametro(url, dadosClienteEstab?.ultimo_documento_base?.quantidade_revisoes)}
                    modulo={modulo}
                    isInventario={isInventario}
                    isFuncoesColaboradores={isFuncoesColaboradores}
                    dadosToken={dadosToken}
                    setShow={setShow}
                    ppraRevisao={ppraRevisao}
                    documentoId={documentoId}
                    isLoading={carregando}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </ModalPadrao>
  );
};
const GerarBotao = ({
  url,
  dadosClienteEstab,
  params = "",
  isInventario,
  isFuncoesColaboradores,
  modulo,
  dadosToken,
  setShow,
  ppraRevisao,
  documentoId,
  isLoading,
}) => {
  const alertFrase = "Documento em produção";

  const documentoBaseId = dadosClienteEstab?.ultimo_documento_base?.id;

  function buscarDadosAcesso(clienteEstabelecimentoId, documentoId) {
    api.get(`clientes/obterAcessoPDF/${clienteEstabelecimentoId}`).then((resposta) => {
      if (isInventario) {
        if (documentoId === "2") {
          switch (resposta.data.find((item) => item.id === documentoId).ppra_id) {
            case "1084":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "Sulzer - Sede - PGR Inventário - Riscos Físicos, Químicos e Biológicos - rev00 .pdf"
              );
              break;
            case "1088":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "Sulzer - Transpetro-Terig - PGR Inventário - Riscos Físicos, Químicos e Biológicos-rev00.pdf"
              );
              break;
            case "1122":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "In-haus - Transpetro-terig - PGR Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
              );
              break;
            case "1110":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "In-haus - In-haus Polo Petroquimico - PGR Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf"
              );
              break;
            case "1204":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "Predial Axel - Transpetro-terig - PGR Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf "
              );
              break;
            case "1212":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "Predial Axel - Sede - PGR Inventário - Riscos Físicos, Químicos e Biológicos - rev00.pdf "
              );
              break;

            case "1380":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "1.1 - Alac - Garibaldi - Sede - Inventário - Riscos Físicos, Químicos e Biológicos - rev01.pdf"
              );
              break;
            default:
              if (dadosClienteEstab.ultimo_documento_base) {
                if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                  }
                } else {
                  if (params !== "") {
                    setShow(false);
                    window.open(url + documentoBaseId + params, "_blank");
                  } else {
                    setShow(false);
                    window.open(url + ppraRevisao.ppra_id, "_blank");
                  }
                }
              } else {
                if (dadosToken().tipo === "2") {
                  toast.info(alertFrase);
                } else {
                  toast.info("Nenhum documento base cadastrado");
                }
              }
              break;
          }
        } else if (documentoId === "3") {
          switch (resposta.data.find((item) => item.id === documentoId).ppra_id) {
            case "1212":
              setShow(false);
              window.open(
                process.env.REACT_APP_CAMINHO_UPLOAD +
                  "Predial Axel - Sede - PGR - Inventário - Risco Ergonômico - rev00.pdf"
              );
              break;

            default:
              if (dadosClienteEstab.ultimo_documento_base) {
                if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                  }
                } else {
                  if (params !== "") {
                    setShow(false);
                    window.open(url + documentoBaseId + params, "_blank");
                  } else {
                    if (
                      typeof resposta.data.find((item) => item.id === documentoId).ergonomia_cadastrada ===
                        "undefined" ||
                      resposta.data.find((item) => item.id === documentoId).ergonomia_cadastrada
                    ) {
                      setShow(false);
                      window.open(url + ppraRevisao.ppra_id, "_blank");
                    } else {
                      if (dadosToken().tipo === "2") {
                        toast.info(alertFrase);
                      } else {
                        toast.info("Não foi cadastrado o inventário de ergonomia");
                      }
                    }
                  }
                }
              } else {
                if (dadosToken().tipo === "2") {
                  toast.info(alertFrase);
                } else {
                  toast.info("Nenhum documento base cadastrado");
                }
              }
              break;
          }
        } else if (documentoId === "4") {
          if (dadosClienteEstab.ultimo_documento_base) {
            if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
              }
            } else {
              if (params !== "") {
                setShow(false);
                window.open(url + documentoBaseId + params, "_blank");
              } else {
                if (
                  typeof resposta.data.find((item) => item.id === documentoId).acidente_cadastrada === "undefined" ||
                  resposta.data.find((item) => item.id === documentoId).acidente_cadastrada
                ) {
                  setShow(false);
                  window.open(url + ppraRevisao.ppra_id, "_blank");
                } else {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Não foi cadastrado o inventário de acidente");
                  }
                }
              }
            }
          } else {
            if (dadosToken().tipo === "2") {
              toast.info(alertFrase);
            } else {
              toast.info("Nenhum documento base cadastrado");
            }
          }
        } else {
          if (dadosClienteEstab.ultimo_documento_base) {
            if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
              }
            } else {
              if (params !== "") {
                setShow(false);
                window.open(url + documentoBaseId + params, "_blank");
              } else {
                setShow(false);
                window.open(url + ppraRevisao.ppra_id, "_blank");
              }
            }
          } else {
            if (dadosToken().tipo === "2") {
              toast.info(alertFrase);
            } else {
              toast.info("Nenhum documento base cadastrado");
            }
          }
        }
      } else if (modulo === "checklist") {
        if (dadosClienteEstab.ultimo_documento_base) {
          console.log(isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id));
          if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
            if (dadosToken().tipo === "2") {
              toast.info(alertFrase);
            } else {
              toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
            }
          } else {
            setShow(false);
            window.open(url + resposta.data.find((item) => item.id === documentoId).ppra_id, "_blank");
          }
        } else {
          if (dadosToken().tipo === "2") {
            toast.info(alertFrase);
          } else {
            toast.info("Nenhum documento base cadastrado");
          }
        }
      } else {
        if (dadosClienteEstab.ultimo_documento_base) {
          if (isEmpty(documentoId)) {
            if (isEmpty(resposta.data.find((item) => item.id === "1").ppra_id)) {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
              }
            } else {
              setShow(false);
              window.open(url + resposta.data.find((item) => item.id === "1").ppra_id, "_blank");
            }
          } else {
            if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
              if (dadosToken().tipo === "2") {
                toast.info(alertFrase);
              } else {
                toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
              }
            } else {
              if (documentoId === "6" || documentoId === "23" || documentoId === "20") {
                if (dadosClienteEstab.ultimo_documento_base) {
                  if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
                    if (dadosToken().tipo === "2") {
                      toast.info(alertFrase);
                    } else {
                      toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                    }
                  } else {
                    if (
                      typeof resposta.data.find((item) => item.id === "3").ergonomia_cadastrada === "undefined" ||
                      resposta.data.find((item) => item.id === "3").ergonomia_cadastrada
                    ) {
                      if (documentoId === "20") {
                        setShow(false);
                        window.open(url + `${dadosClienteEstab.codigo}/${ppraRevisao.ppra_id}`, "_blank");
                      } else {
                        setShow(false);
                        window.open(url + ppraRevisao.ppra_id, "_blank");
                      }
                    } else {
                      if (dadosToken().tipo === "2") {
                        toast.info(alertFrase);
                      } else {
                        toast.info("Não foi cadastrado o inventário de ergonomia");
                      }
                    }
                  }
                } else {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Nenhum documento base cadastrado");
                  }
                }
                // documentoId === "7" ||
              } else if (documentoId === "7" || documentoId === "24" || documentoId === "21") {
                if (dadosClienteEstab.ultimo_documento_base) {
                  if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
                    if (dadosToken().tipo === "2") {
                      toast.info(alertFrase);
                    } else {
                      toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                    }
                  } else {
                    if (
                      typeof resposta.data.find((item) => item.id === "4").acidente_cadastrada === "undefined" ||
                      resposta.data.find((item) => item.id === "4").acidente_cadastrada
                    ) {
                      if (documentoId === "21") {
                        setShow(false);
                        window.open(url + `${dadosClienteEstab.codigo}/${ppraRevisao.ppra_id}`, "_blank");
                      } else {
                        setShow(false);
                        window.open(url + ppraRevisao.ppra_id, "_blank");
                      }
                    } else {
                      if (dadosToken().tipo === "2") {
                        toast.info(alertFrase);
                      } else {
                        toast.info("Não foi cadastrado o inventário de acidente");
                      }
                    }
                  }
                } else {
                  if (dadosToken().tipo === "2") {
                    toast.info(alertFrase);
                  } else {
                    toast.info("Nenhum documento base cadastrado");
                  }
                }
                // } else if (documentoId === "7") {
                //   switch (resposta.data.find((item) => item.id === documentoId).ppra_id) {
                //     case "960":
                //       window.open(
                //         process.env.REACT_APP_CAMINHO_UPLOAD + "acesso_alpinismo-CMPC-PGR_Plano_de_açao-Risco_acidente.pdf"
                //       );
                //       break;

                //     case "1082":
                //       window.open(
                //         process.env.REACT_APP_CAMINHO_UPLOAD + "2.3-ACESSO ALPINISMO - CMPC-BIO - GuaibaRS - PGR Plano de Acao - Risco Acidente - rev00.pdf"
                //       );
                //       break;

                //     case "1227":
                //       window.open(
                //         process.env.REACT_APP_CAMINHO_UPLOAD + "2.3 - Acesso Alpinismo - Gerdau Charqueadas - Plano de Açao - Risco Acidente.pdf"
                //       );
                //       break;

                //     case "1217":
                //       window.open(
                //         process.env.REACT_APP_CAMINHO_UPLOAD + "2.3 - Acesso Alpinismo - Gerdau Sapucaia - Plano de Açao - Risco Acidente.pdf"
                //       );
                //       break;

                //     default:
                //       if (dadosClienteEstab.ultimo_documento_base) {
                //         if (isEmpty(resposta.data.find((item) => item.id === documentoId).ppra_id)) {
                //           if (dadosToken().tipo === "2") {
                //             toast.info(alertFrase);
                //           } else {
                //             toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                //           }
                //         } else {
                //           if (
                //             typeof resposta.data.find((item) => item.id === "4").acidente_cadastrada === "undefined" ||
                //             resposta.data.find((item) => item.id === "4").acidente_cadastrada
                //           ) {
                //             if (documentoId === "21") {
                //               setShow(false);
                //               window.open(url + `${dadosClienteEstab.codigo}/${ppraRevisao.ppra_id}`, "_blank");
                //             } else {
                //               setShow(false);
                //               window.open(url + ppraRevisao.ppra_id, "_blank");
                //             }
                //           } else {
                //             if (dadosToken().tipo === "2") {
                //               toast.info(alertFrase);
                //             } else {
                //               toast.info("Não foi cadastrado o inventário de acidente");
                //             }
                //           }
                //         }
                //       } else {
                //         if (dadosToken().tipo === "2") {
                //           toast.info(alertFrase);
                //         } else {
                //           toast.info("Nenhum documento base cadastrado");
                //         }
                //       }
                //       break;
                //   }
              } else if (params === "ergonomia") {
                setShow(false);
                window.open(url + ppraRevisao.ppra_id, "_blank");
              } else if (isFuncoesColaboradores) {
                api.get(`clientes/obterFuncoesColaboradores?ppra_id=${ppraRevisao.ppra_id}`).then((resposta) => {
                  if (isEmpty(resposta.data[0])) {
                    if (dadosToken().tipo === "2") {
                      toast.info(alertFrase);
                    } else {
                      toast.info("Não foi cadastrado o responsável que forneceu a lista.");
                    }
                  } else {
                    setShow(false);
                    window.open(url + clienteEstabelecimentoId + "/" + ppraRevisao.ppra_id, "_blank");
                  }
                });
              } else if (params !== "") {
                setShow(false);
                window.open(url + documentoBaseId + params, "_blank");
              } else {
                setShow(false);
                window.open(url + clienteEstabelecimentoId + "/" + ppraRevisao.ppra_id, "_blank");
              }
            }
          }
        } else {
          if (dadosToken().tipo === "2") {
            toast.info(alertFrase);
          } else {
            toast.info("Não existe documento base");
          }
        }
      }
    });
  }
  return (
    <Botao
      icone="print"
      cor="secondary"
      conteudo="Gerar"
      disabled={isEmpty(dadosClienteEstab) || isLoading}
      isLoading={isLoading}
      onClick={() => {
        buscarDadosAcesso(dadosClienteEstab.codigo, documentoId);
        // console.log(documentoId);
      }}
    />
  );
};

const ModalART = ({ showModalART, setShowModalART, clienteId, dadosToken, estabelecimentos }) => {
  const [arquivo, setArquivo] = useState();
  const [ano, setAno] = useState(moment().format("YYYY"));
  const [dados, setDados] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [servicoSelecionado, setServicoSelecionado] = useState();
  const [showSeleciona, setShowSeleciona] = useState(false);
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState();
  const [sedeDados, setSedeDados] = useState([]);

  useEffect(() => {
    buscarDados(clienteId);
    buscarSede(clienteId);
    buscarServico();
  }, [clienteId]);

  async function buscarDados(clienteId) {
    await api.get(`/gro/Art/buscarDados?cliente_id=${clienteId}`).then((resposta) => {
      setDados(resposta.data);
    });
  }

  async function buscarSede(clienteId) {
    await api.get(`/gro/Art/buscarSede?cliente_id=${clienteId}`).then((resposta) => {
      setSedeDados(resposta.data);
    });
  }

  const dadosTabela = dados?.map((dados) => ({
    id: dados.id,
    nome: dados.art,
    ano: dados.ano,
    servico_id: dados.servico_id,
    servico: dados.servico,
    estabelecimento_nome_fantasia: dados.nome_generico,
    estabelecimento_id: dados.estabelecimento_id,
  }));

  async function deletarPDF(idDeletar, arquivoDeletar) {
    if (window.confirm(`Você tem certeza que deseja deletar este PDF?`)) {
      api.post(`upload/deletarArquivo`, {
        nome_arquivo: arquivoDeletar,
        caminho: `art/`,
      });
      await api.delete(`/gro/Art/deletar/${idDeletar}`).then(() => {
        buscarDados(clienteId);
      });
    }
  }

  const DadosTabela = ({ dadosTabela }) => {
    const [isEditando, setIsEditando] = useState(false);
    const [estabelecimentoEditar, setEstabelecimentoEditar] = useState(dadosTabela.estabelecimento_id);
    const [servicoEditar, setServicoEditar] = useState(dadosTabela.servico_id);
    const [anoEditar, setAnoEditar] = useState(dadosTabela.ano);

    function editarDados(e, artId, estabelecimentoId, servicoId, ano) {
      e.preventDefault();
      api
        .put(`gro/art/editar/${artId}`, {
          estabelecimento_id: estabelecimentoId,
          servico_id: servicoId,
          ano: ano,
        })
        .then(() => {
          buscarDados(clienteId);
          setIsEditando(false);
        });
    }

    return (
      <>
        <tr className="fw-bold">
          {isEditando ? (
            <>
              <td>
                <Select
                  id="selectEstab"
                  placeholder="Selecione"
                  name="cliente_estabeleciment_id"
                  onChange={(options, select) => {
                    setEstabelecimentoEditar(options.value);
                  }}
                  defaultValue={{ label: dadosTabela.estabelecimento_nome_fantasia, value: 1 }}
                  options={estabelecimentos.map((cliEstab) => ({
                    label: cliEstab.estabelecimento.nome_generico,
                    value: cliEstab.cod_estabelecimento,
                  }))}
                />
              </td>

              <td>
                <Select
                  id="selectServico"
                  placeholder="Selecione"
                  name="select_servico"
                  defaultValue={{
                    label: dadosTabela.servico,
                    value: 1,
                  }}
                  onChange={(e) => {
                    setServicoEditar(e.value);
                  }}
                  options={dadosServico}
                />
              </td>

              <td>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="inputAno"
                  onChange={(e) => setAnoEditar(e.target.value)}
                  defaultValue={anoEditar}
                />
              </td>
            </>
          ) : (
            <>
              <td>{dadosTabela.estabelecimento_nome_fantasia ? dadosTabela.estabelecimento_nome_fantasia : " - "}</td>
              <td>{dadosTabela.servico}</td>
              <td>{dadosTabela.ano}</td>
            </>
          )}
          <td className="text-center">
            <Botao
              title="Visualizar PDF"
              icone="file-pdf"
              cor="secondary"
              alvo={`${process.env.REACT_APP_API_URL}/gro/pgr/pdf/art/gerar/${dadosTabela.id}`}
            />
          </td>

          {dadosToken.tipo <= "1" && (
            <td className="text-center">
              {isEditando ? (
                <>
                  <BotaoGrupo>
                    <BotaoVoltar onClick={() => setIsEditando(false)} />
                    <BotaoSalvar
                      title="Salvar alteração"
                      onClick={(e) => editarDados(e, dadosTabela.id, estabelecimentoEditar, servicoEditar, anoEditar)}
                    />
                  </BotaoGrupo>
                </>
              ) : (
                <>
                  <BotaoGrupo>
                    <BotaoEditar onClick={() => setIsEditando(true)} />
                    <BotaoDeletar onClick={() => deletarPDF(dadosTabela.id, dadosTabela.nome)} />
                  </BotaoGrupo>
                </>
              )}
            </td>
          )}
        </tr>
      </>
    );
  };

  function handleSubmit(e, arquivo) {
    if (!servicoSelecionado) {
      toast.info("Selecione um serviço");
    } else {
      async function uploadArquivo(e, arquivo) {
        e.preventDefault();

        const formataNome = (str) => {
          str = str.replace(/[ÀÁÂÃ]/g, "A");
          str = str.replace(/[àáâã]/g, "a");
          str = str.replace(/[ÈÉÊË]/g, "E");
          str = str.replace(/[èéêẽ]/g, "e");
          str = str.replace(/[ÌÍÎĨ]/g, "I");
          str = str.replace(/[ìíîĩ]/g, "i");
          str = str.replace(/[òóôõ]/g, "o");
          str = str.replace(/[ÒÓÔÕ]/g, "O");
          str = str.replace(/[ÙÚÛŨ]/g, "U");
          str = str.replace(/[ùúûũ]/g, "u");
          str = str.replace(/[/]/g, "_");
          str = str.replace(/[" "]/g, "_");
          str = str.replace(/[!@#$%&*<>,.;:?°º+|]/g, "");

          return str;
        };

        const novoNome = formataNome(`14 - ${arquivo.name.split(".")[0]}`);
        const formData = new FormData();
        formData.append("arquivo", arquivo);
        formData.append("nomeNovo", novoNome);
        formData.append("nomeArquivo", arquivo.name);

        // Upload
        await api
          .post("/upload/inserirArt", formData, {
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          })
          .then(() => inserirArt(clienteId, novoNome, ano))
          .catch((e) => alert(e.response.data.mensagem));

        buscarDados(clienteId);
      }
      uploadArquivo(e, arquivo);
    }
  }

  async function inserirArt(clienteId, novoNome, ano) {
    await api.post("/gro/Art/inserir", {
      cliente_id: clienteId,
      estabelecimento_id: showSeleciona
        ? estabelecimentoSelecionado.cod_estabelecimento
        : sedeDados[0].cod_estabelecimento,
      servico_id: servicoSelecionado,
      art: novoNome,
      nomeArquivo: arquivo.name,
      ano: ano,
    });
  }

  async function buscarServico() {
    await api.get("gro/Art/servicos").then((resposta) => {
      setServicos(resposta.data);
    });
  }

  const dadosServico = servicos?.map((e) => ({
    label: e.servico,
    value: e.codigo,
  }));

  return (
    <ModalSubmit
      show={showModalART}
      setShow={setShowModalART}
      tamanho="lg"
      titulo={"Anotação Responsável Técnica (ART)"}
      tituloSpan={dadosToken.tipo <= "1" ? "Upload" : "PDF"}
    >
      {dadosToken.tipo <= "1" && (
        <>
          <div className="row">
            <div className="col-1 mr-3 mt-1">
              <label className="form-label " htmlFor="servico">
                Serviços:{" "}
              </label>
            </div>
            <div className="col">
              <Select
                id="selectServico"
                className="mb-4"
                placeholder="Selecione"
                name="select_servico"
                onChange={(e) => {
                  setServicoSelecionado(e.value);
                }}
                options={dadosServico}
              />
            </div>
          </div>
        </>
      )}
      {dadosToken.tipo <= "1" && (
        <>
          <div className="row pt-2">
            <div className="col-4">
              <div className="row">
                <div className="col-2">
                  <label className="form-label mr-3" htmlFor="inputAno">
                    Ano:
                  </label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="inputAno"
                    onChange={(e) => setAno(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <InputFile nomeArquivo={arquivo} handleChange={(arquivo) => setArquivo(arquivo)} />
            </div>
            <div className="col-2 text-end">
              <BotaoSalvar temConteudo onClick={(e) => handleSubmit(e, arquivo)} />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-2">
              <div className="form-check">
                <input
                  className="form-check-radio"
                  type="radio"
                  name="radio"
                  id="cliente"
                  onClick={(e) => setShowSeleciona(false)}
                  checked={!showSeleciona}
                />
                <label htmlFor="cliente" className="ml-2">
                  Sede
                </label>
              </div>
            </div>

            <div className="col-3">
              <div className="form-check">
                <input
                  className="form-check-radio"
                  type="radio"
                  name="radio"
                  id="estabelecimento"
                  onClick={(e) => setShowSeleciona(true)}
                />
                <label htmlFor="estabelecimento" className="ml-2">
                  Estabelecimento
                </label>
              </div>
            </div>
            {showSeleciona && (
              <>
                <div className="col">
                  <Select
                    id="selectEstab"
                    placeholder="Selecione"
                    name="cliente_estabeleciment_id"
                    onChange={(options, select) => {
                      setEstabelecimentoSelecionado(options.value);
                    }}
                    options={estabelecimentos.map((cliEstab) => ({
                      label: cliEstab.estabelecimento.nome_generico,
                      value: cliEstab,
                    }))}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}

      {dadosTabela && (
        <div className="row mt-4">
          <TabelaPadrao
            colunas={[
              {
                nome: "Estabelecimento",
                text: "center",
                width: "30%",
              },
              {
                nome: "Serviço",
                text: "center",
                width: "20%",
              },
              {
                nome: "Ano",
                text: "center",
                width: "10%",
              },
              {
                nome: "PDF",
                text: "center",
                width: "5%",
              },
              dadosToken.tipo <= "1" && {
                nome: "Ações",
                text: "center",
                width: "15%",
              },
            ]}
            itens={dadosTabela}
          >
            {dadosTabela.map((dadosTabela, index) => (
              <DadosTabela dadosTabela={dadosTabela} key={index} />
            ))}
          </TabelaPadrao>
        </div>
      )}
    </ModalSubmit>
  );
};

const ModalPCMSO = ({ showModalPCMSO, setShowModalPCMSO, estabelecimentos, buscarDadosCliente }) => {
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState(estabelecimentos[0].codigo);

  let dadosTabela = [
    {
      numero: "0",
      coluna: "Documento Base",
      link: "documentoBase/gerar/",
      modulo: "docBase",
      documentoId: "1",
    },
    {
      numero: "1.1",
      coluna: "Inventário de Risco (Físico, químico,  e biológico)",
      link: "quadros/gerarRevisao/",
      modulo: "ppra",
      documentoId: "2",
    },
    {
      numero: "3",
      coluna: "Perfil da Função",
      link: "perfisFuncoes/gerar/",
      modulo: "clienteId",
      documentoId: "8",
    },
    {
      numero: "4",
      coluna: "Funções X Colaboradores",
      link: "funcoesFuncionarios/gerar/",
      modulo: "funcoesColaboradores",
      documentoId: "9",
    },
    {
      numero: "6",
      coluna: "Atividades Especiais",
      link: "atividadesEspeciais/gerar/",
      modulo: "ppra",
      documentoId: "11",
    },
    {
      numero: "8",
      coluna: "Produtos Químicos",
      link: "produtosQuimicos/gerar/",
      modulo: "ppra",
      documentoId: "14",
    },
    {
      numero: "9",
      coluna: "LINACH (cancerígenos)",
      link: "linachs/gerar/",
      modulo: "ppra",
      documentoId: "15",
    },
    {
      numero: "12.1",
      coluna: "GHE - Risco Físico, químico e biológico",
      link: "ghes/gerarGHE/",
      modulo: "clienteId",
      documentoId: "22",
    },
  ];

  const DadosTabela = ({ dadosTabela }) => {
    let urlPadrao = process.env.REACT_APP_API_URL + "/gro/pgr/pdf/" + dadosTabela.link;
    return (
      <>
        <tr>
          <td className="text-center">{dadosTabela.numero}</td>
          <td>{dadosTabela.coluna}</td>
          <td className="text-center">
            <Botao
              icone="file-pdf"
              cor="secondary"
              onClick={() => {
                buscarDadosCliente(
                  estabelecimentoSelecionado,
                  dadosTabela.modulo,
                  urlPadrao,
                  dadosTabela.documentoId,
                  (dadosTabela.link === "quadros/gerar/" ||
                    dadosTabela.link === "quadros/gerarRevisao/" ||
                    dadosTabela.link === "apres/gerarRevisao/" ||
                    dadosTabela.link === "acidente/gerarRevisao/") &&
                    true
                );
              }}
            />
          </td>
        </tr>
      </>
    );
  };
  return (
    <ModalPadrao show={showModalPCMSO} setShow={setShowModalPCMSO} tamanho="lg" titulo={"Documentos para PCMSO"}>
      <div className="row">
        <p>Documentos recomendados para clínica do PCMSO (enviar todos abaixo)</p>
      </div>
      {estabelecimentos.length > 1 && (
        <>
          <div className="row">
            <div className="col-2 mr-4 mt-1">
              <label className="form-label " htmlFor="selectEstabelecimento">
                Estabelecimento:{" "}
              </label>
            </div>
            <div className="col">
              <Select
                id="selectEstabelecimento"
                className="mb-4"
                placeholder="Selecione"
                name="select_estabelecimento"
                onChange={(e) => {
                  setEstabelecimentoSelecionado(e.value);
                }}
                options={estabelecimentos.map((item) => ({
                  label: item.estabelecimento.nome_generico,
                  value: item.codigo,
                }))}
                defaultValue={{
                  label: estabelecimentos[0].estabelecimento.nome_generico,
                  value: estabelecimentos[0].codigo,
                }}
              />
            </div>
          </div>
        </>
      )}

      <div className="row">
        <TabelaPadrao
          colunas={[
            { nome: "N°", width: "5%", text: "center" },
            { nome: "Documento" },
            { nome: "Ações", width: "5%", text: "center" },
          ]}
          itens={dadosTabela}
        >
          {dadosTabela.map((item, index) => (
            <DadosTabela dadosTabela={item} key={index} />
          ))}
        </TabelaPadrao>
      </div>
    </ModalPadrao>
  );
};

export const ModalOutrosDocumentos = ({ show, setShow, estabelecimentos, clienteId, dadosToken }) => {
  const [arquivo, setArquivo] = useState();
  const [nomeDocumento, setNomeDocumento] = useState();
  const [revisao, setRevisao] = useState();
  const [data, setData] = useState();
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState();
  const [dados, setDados] = useState();

  useEffect(() => {
    obterDadosTabela(clienteId);
  }, [clienteId]);

  async function obterDadosTabela(clienteId) {
    await api.get(`ppras/obterClienteEstabelecimentoComDocumentos/${clienteId}`).then((resposta) => {
      setDados(resposta.data);
    });
  }

  function handleSubmit(e, arquivo) {
    if (!estabelecimentoSelecionado) {
      toast.info("Selecione um estabelecimento");
    } else if (!data) {
      toast.info("Selecione uma data");
    } else {
      async function uploadArquivo(e, arquivo) {
        e.preventDefault();

        const novoNome = `${formataNome(nomeDocumento)}_${estabelecimentoSelecionado}`;
        const formData = new FormData();
        formData.append("arquivo", arquivo);
        formData.append("nomeNovo", novoNome);
        formData.append("nomeArquivo", arquivo.name);
        formData.append("caminho", `${estabelecimentoSelecionado}/documentos/`);
        formData.append("clienteEstabelecimento", estabelecimentoSelecionado);

        // Upload
        await api
          .post("/upload/inserirOutrosDocumentos", formData, {
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          })
          .then((resposta) => inserir(resposta.data.nomeArquivo))
          .catch((e) => alert(e.response.data.mensagem));

        obterDadosTabela(clienteId);
      }
      uploadArquivo(e, arquivo);
    }
  }

  async function inserir(novoNome) {
    await api
      .post("ppras/inserirPDF", {
        cliente_id: clienteId,
        cliente_estabelecimento_id: estabelecimentoSelecionado,
        nome: nomeDocumento,
        revisao: revisao,
        url: novoNome,
        data: data,
      })
      .then(() => {
        obterDadosTabela(clienteId);
        toast.success("Documento adicionado com sucesso");
      });
  }

  async function deletar(nomeArquivo, clienteEstabelecimento, idDeletar) {
    if (window.confirm(`Você tem certeza que deseja deletar este PDF?`)) {
      api
        .post(`upload/deletarArquivo`, {
          nome_arquivo: nomeArquivo,
          caminho: `outrosDocumentos/${clienteEstabelecimento}/documentos/`,
        })
        .then(() => {
          deletarDocumento(idDeletar);
        });
    }
  }

  async function deletarDocumento(idDeletar) {
    await api.delete(`ppras/deletarPdf/${idDeletar}`).then(() => {
      obterDadosTabela(clienteId);
      toast.success("Documento deletado com sucesso");
    });
  }

  const TabelaDocumentos = ({ item, index }) => {
    const [editando, setEditando] = useState(false);
    const [novoEstabelecimento, setNovoEstabelecimento] = useState();
    const [novoNome, setNovoNome] = useState();
    const [novaData, setNovData] = useState();
    const [novaRevisao, setNovaRevisao] = useState();

    async function editar(e, idEditar, novoEstabelecimento, novoNome) {
      e.preventDefault();
      await api
        .put(`ppras/editarPdfOutros/${idEditar}`, {
          cliente_estabelecimento_id: novoEstabelecimento,
          nome: novoNome,
          data: novaData,
          revisao: novaRevisao,
        })
        .then(() => {
          obterDadosTabela(clienteId);
          toast.success("Documento editado com sucesso");
        });
    }

    return (
      <>
        <tr key={index}>
          {editando ? (
            <>
              <td>
                <Select
                  defaultValue={
                    isEmpty(item.nome_generico) ? { label: item.nome_fantasia } : { label: item.nome_generico }
                  }
                  onChange={(e) => {
                    setNovoEstabelecimento(e.value);
                  }}
                  options={estabelecimentos.map((cliEstab) => ({
                    label: cliEstab.estabelecimento.nome_generico,
                    value: cliEstab.codigo,
                  }))}
                />
              </td>
              <td>
                <input
                  type="text"
                  defaultValue={item.nome}
                  className="form-control"
                  onChange={(e) => setNovoNome(e.target.value)}
                />
              </td>

              <td>
                <input
                  type="text"
                  defaultValue={item.revisao}
                  className="form-control"
                  onChange={(e) => setNovaRevisao(e.target.value)}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={item.data}
                  name="dataDocumento"
                  id="dataDocumento"
                  onChange={(e) => setNovData(e.target.value)}
                />
              </td>
            </>
          ) : (
            <>
              <td>{isEmpty(item.nome_generico) ? item.nome_fantasia : item.nome_generico}</td>
              <td>{item.nome}</td>
              <td className={isEmpty(item.revisao) && "text-center"}>{isEmpty(item.revisao) ? " - " : item.revisao}</td>
              <td>{item.data === "0000-00-00" ? " - " : moment(item.data).format("DD/MM/YYYY")}</td>
            </>
          )}
          <td className="text-center">
            <Botao
              icone="file-pdf"
              cor="secondary"
              alvo={
                process.env.REACT_APP_CAMINHO_UPLOAD +
                `outrosDocumentos/${item.cliente_estabelecimento_id}/documentos/` +
                item.url
              }
            />
          </td>
          {dadosToken.tipo <= "1" && (
            <td className="text-center">
              {editando ? (
                <BotaoGrupo>
                  <BotaoSalvar onClick={(e) => editar(e, item.id, novoEstabelecimento, novoNome)} />
                  <BotaoVoltar onClick={() => setEditando(!editando)} />
                </BotaoGrupo>
              ) : (
                <BotaoGrupo>
                  <BotaoEditar onClick={() => setEditando(!editando)} />
                  <BotaoDeletar onClick={() => deletar(item.url, item.cliente_estabelecimento_id, item.id)} />
                </BotaoGrupo>
              )}
            </td>
          )}
        </tr>
      </>
    );
  };

  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      tamanho="lg"
      titulo={"Outros Documentos"}
      tituloSpan={dadosToken.tipo <= "1" ? "Upload" : "PDF"}
    >
      <>
        {dadosToken.tipo <= "1" && (
          <div className="row">
            <div className="col-12">
              <p>
                O tamanho do arquivo deve ser menor ou igual a <span className="badge bg-success">4500kB</span>
                <br />
                Arquivo pode ser em formato:
                <span className="badge bg-success">.pdf</span>
                <span className="badge bg-success ml-1">.jpg</span>
                <span className="badge bg-success ml-1">.jpeg</span>
                <span className="badge bg-success ml-1">.xls</span>
                <span className="badge bg-success ml-1">.xlsx</span>
                <span className="badge bg-success ml-1">.excel</span>
              </p>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-4 mt-2 mr-2">
                  <label>Selecione um estabelecimento: </label>
                </div>
                <div className="col">
                  <Select
                    onChange={(e) => {
                      setEstabelecimentoSelecionado(e.value);
                    }}
                    options={estabelecimentos.map((cliEstab) => ({
                      label: cliEstab.estabelecimento.nome_generico,
                      value: cliEstab.codigo,
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {dadosToken.tipo <= "1" && (
           <>
            <div className="row mt-3">
              <div className="col-auto mr-2">
                <label className="form-label mr-1 mt-1" htmlFor="documento">
                  Documento:
                </label>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="documento"
                  onChange={(e) => setNomeDocumento(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1 mr-2">
                <label className="form-label mr-3 mt-1" htmlFor="revisao">
                  Revisão:
                </label>
              </div>
              <div className="col-2">
                <input type="text" className="form-control form-control-sm" id="revisao" onChange={(e) => setRevisao(e.target.value)} required />
              </div>

              <div className="col-auto mt-1">
                <label htmlFor="">Data:</label>
              </div>
              <div className="col-2">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  onChange={(e) => setData(e.target.value)}
                  name="dataDocumento"
                  id="dataDocumento"
                />
              </div>

              <div className="col-5">
                <InputFile nomeArquivo={arquivo} handleChange={(arquivo) => setArquivo(arquivo)} />
              </div>
              <div className="col mt-2 mr-2">
                <BotaoSalvar temConteudo className="float-end mb-4 ml-4" onClick={(e) => handleSubmit(e, arquivo)} />
              </div>
            </div>
          </>
        )}
        <div className="row">
          <TabelaPadrao
            colunas={[
              { nome: "Estabelecimento" },
              { nome: "Arquivo" },
              { nome: "Revisão" },
              { nome: "Data" },
              { nome: "Visualizar", width: "10%", text: "center" },
              dadosToken.tipo <= 1 && {
                nome: "Ações",
                width: "13%",
                text: "center",
              },
            ]}
            itens={dados}
          >
            {dados?.map((item, index) => (
              <TabelaDocumentos item={item} index={index} />
            ))}
          </TabelaPadrao>
        </div>
      </>
    </ModalPadrao>
  );
};

const ModalInformativos = ({ show, setShow }) => {
  const [informativosCadastrados, setInformativosCadastrados] = useState();
  const [carregandoTabelaInformativos, setCarregandoTabelaInformativos] = useState(true);
  const [filtro, setFiltro] = useState("");
  const [showdata, setShowData] = useState(false);
  const [dataInicio, setDataInicio] = useState();
  const [dataFinal, setDataFinal] = useState();

  useEffect(() => {
    api.get(`comercial/Informativos/obterInformativos`).then((resposta) => {
      setInformativosCadastrados(resposta.data);
      setCarregandoTabelaInformativos(false);
    });
  }, []);

  const informativosFiltrados = informativosCadastrados?.filter(
    (informativo) =>
      validarFiltroInformativos(informativo, filtro) && filtrarDataInicioFim(dataInicio, dataFinal, informativo.data)
  );

  function validarFiltroInformativos(informativo, filtro) {
    let informativoNome = informativo.assunto;

    if (!isEmpty(informativo.abrangencia)) {
      informativoNome = informativo.assunto + " " + informativo.abrangencia;
    }

    return filtrar(informativoNome, filtro);
  }

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  function limparFiltrosData() {
    setDataInicio("");
    setDataFinal("");
    document.getElementById("inputPesquisaFiltroInicio").value = "";
    document.getElementById("inputPesquisaFiltroFim").value = "";
  }

  const TabelaInformativos = ({ item, index }) => {
    return (
      <>
        <tr key={index}>
          <td>{item.assunto}</td>
          <td>{item.abrangencia}</td>
          <td>
            {isEmpty(item.data)
              ? "Não cadastrada"
              : item.data === "0000-00-00"
              ? " - "
              : moment(item.data).format("DD/MM/YYYY")}
          </td>
          <td className="text-center">
            <Botao
              icone="file-pdf"
              cor="secondary"
              alvo={`${process.env.REACT_APP_CAMINHO_UPLOAD}informativos/${item.url}`}
            />
          </td>
        </tr>
      </>
    );
  };

  return (
    <ModalSubmit show={show} setShow={setShow} titulo={"Informativos"} tamanho="lg">
      <div className="row p-3">
        <div className="col">
          <Filter
            handleFilter={(e) => {
              handleFilter(e.target.value);
            }}
            disableAddButton
          />
          <div className="row mt-3 mb-3">
            <div className="col-auto">
              <label htmlFor="input-data" className="col-form-label">
                <Botao
                  conteudo={"Filtrar por data"}
                  className="bg-secondary border border-secondary"
                  onClick={() => {
                    setShowData(true);
                  }}
                />
              </label>
            </div>
            {showdata && (
              <>
                <div className="col-auto">
                  <label className="col-form-label float-end mt-1 ">Início:</label>
                </div>
                <div className="col-auto mt-1">
                  <input
                    id="inputPesquisaFiltroInicio"
                    type="date"
                    className="form-control"
                    onChange={(e) => setDataInicio(e.target.value)}
                  />
                </div>
                <div className="col-auto mt-1">
                  <label className="col-form-label float-end  ">Fim:</label>
                </div>
                <div className="col-auto mt-1">
                  <input
                    id="inputPesquisaFiltroFim"
                    type="date"
                    className="form-control"
                    onChange={(e) => setDataFinal(e.target.value)}
                  />
                </div>
                {!isEmpty(dataInicio) && !isEmpty(dataFinal) && (
                  <div className="col-auto mt-1">
                    <button type="button" className="btn btn-outline-primary" onClick={() => limparFiltrosData()}>
                      Limpar filtro
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <TabelaPadrao
            filtro={filtro}
            itensFiltrados={informativosFiltrados}
            colunas={[
              { nome: "Assunto" },
              { nome: "Abrangência" },
              { nome: "Data", width: "14%" },
              { nome: "Visualização", text: "center", width: "10%" },
            ]}
            itens={informativosCadastrados}
            carregando={carregandoTabelaInformativos}
          >
            {informativosFiltrados?.map((item, index) => (
              <TabelaInformativos item={item} key={index} />
            ))}
          </TabelaPadrao>
        </div>
      </div>
    </ModalSubmit>
  );
};

//upload pdf
const UploadPDF = ({ show, setShow, botao, ergonomia, clienteId, dadosToken, arquivoNome }) => {
  const [dadosCliente, setDadosCliente] = useState();
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState();
  const [estabelecimentoSelecionadoNome, setEstabelecimentoSelecionadoNome] = useState();
  const [arquivo, setArquivo] = useState();
  const [dadosUploadPDF, setDadosUploadPDF] = useState();
  const [isSede, setIsSede] = useState(false);

  const tipo = ergonomia ? 1 : 0;

  useEffect(() => {
    async function buscarDados(clienteId) {
      await api
        .get(`clientes/obterClienteComEstabelecimentos?cliente_codigo=${clienteId}`)
        .then((resposta) => setDadosCliente(resposta.data));
    }

    async function buscarDadosUploadPDF() {
      await api
        .get(
          `/gro/Art/buscarUploadPDF?cliente_estabelecimento_id=${estabelecimentoSelecionado}&cliente_id=${clienteId}&tipo=${
            ergonomia ? 1 : 0
          }&documento=${arquivoNome}`
        )
        .then((resposta) => setDadosUploadPDF(resposta.data));
    }
    buscarDados(clienteId);
    buscarDadosUploadPDF();
  }, [clienteId, estabelecimentoSelecionado, arquivoNome, ergonomia]);

  const formataNome = (str) => {
    str = str.replace(/[ÀÁÂÃ]/g, "A");
    str = str.replace(/[àáâã]/g, "a");
    str = str.replace(/[ÈÉÊË]/g, "E");
    str = str.replace(/[èéêẽ]/g, "e");
    str = str.replace(/[ÌÍÎĨ]/g, "I");
    str = str.replace(/[ìíîĩ]/g, "i");
    str = str.replace(/[òóôõ]/g, "o");
    str = str.replace(/[ÒÓÔÕ]/g, "O");
    str = str.replace(/[ÙÚÛŨ]/g, "U");
    str = str.replace(/[ùúûũ]/g, "u");
    str = str.replace(/[/]/g, "_");
    str = str.replace(/[" "]/g, "_");
    str = str.replace(/[!@#$%&*<>,.;:?°º+|]/g, "");

    return str;
  };

  async function buscarDadosUploadPDF() {
    await api
      .get(
        `/gro/Art/buscarUploadPDF?cliente_estabelecimento_id=${estabelecimentoSelecionado}&cliente_id=${clienteId}&tipo=${
          ergonomia ? 1 : 0
        }&documento=${arquivoNome}`
      )
      .then((resposta) => setDadosUploadPDF(resposta.data));
  }

  function inserirUploadPDF(estabelecimentoSelecionado, novoNome) {
    api
      .post("gro/Art/inserirUploadPDF", {
        cliente_id: clienteId,
        cliente_estabelecimento_id: estabelecimentoSelecionado,
        tipo: tipo,
        documento: arquivoNome,
        url: novoNome,
      })
      .then(() => buscarDadosUploadPDF(estabelecimentoSelecionado));
  }

  function deletarPDF(idDeletar, arquivoDeletar) {
    if (window.confirm("Você tem certeza que deseja deletar este PDF?")) {
      api
        .post(`upload/DeletarArquivo`, {
          nome_arquivo: arquivoDeletar,
          caminho: "uploadPDF/",
        })
        .then(() => excluirUploadPDF(idDeletar));
    }
  }

  function excluirUploadPDF(uploadPDF_id) {
    api
      .delete(`gro/Art/deletarUploadPdf?id=${uploadPDF_id}`)
      .then(() => buscarDadosUploadPDF(estabelecimentoSelecionado, clienteId));
  }

  function novoNomeArquivo() {
    switch (arquivoNome) {
      case "GHE":
        return ergonomia
          ? `${dadosCliente?.nome_fantasia.toLowerCase()}-${
              isSede ? "sede" : estabelecimentoSelecionadoNome
            }-PGR_GHE-Risco_ergonômico`
          : `${dadosCliente?.nome_fantasia.toLowerCase()}-${
              isSede ? "sede" : estabelecimentoSelecionadoNome
            }-PGR_GHE-Risco_acidente`;

      case "plano_de_acao":
        return ergonomia
          ? `${dadosCliente?.nome_fantasia.toLowerCase()}-${
              isSede ? "sede" : estabelecimentoSelecionadoNome
            }-PGR_Plano_de_ação-Risco_ergonômico`
          : `${dadosCliente?.nome_fantasia.toLowerCase()}-${
              isSede ? "sede" : estabelecimentoSelecionadoNome
            }-PGR_Plano_de_ação-Risco_acidente`;

      default:
        break;
    }
  }

  async function uploadArquivo(e, arquivo) {
    e.preventDefault();

    const novoNome = novoNomeArquivo();
    const formData = new FormData();
    formData.append("arquivo", arquivo);
    formData.append("nomeNovo", formataNome(novoNome));
    formData.append("nomeArquivo", arquivo.name);

    await api
      .post("/upload/inserirUploadPDF", formData, {
        headers: {
          "Content-Type": `multipart/form-data;`,
        },
      })
      .then((resposta) => inserirUploadPDF(estabelecimentoSelecionado, resposta.data.nomeArquivo))
      .catch((e) => alert(e.response.data.mensagem));
  }

  function handleSubmit(e, arquivo) {
    if (!estabelecimentoSelecionado) {
      toast.info("Selecione um estabelecimento!");
    } else if (!arquivo) {
      toast.info("Selecione um PDF para envio!");
    } else {
      uploadArquivo(e, arquivo);
    }
  }
  const estabelecimentoOption = dadosCliente?.cliente_estabelecimentos.map((e) => ({
    label: e.estabelecimento.nome_generico,
    value: e.codigo,
    tipo: e.tipo,
  }));
  const TabelaDados = ({ index, item }) => {
    return (
      <>
        <tr key={index}>
          <td>{item.nome_estabelecimento}</td>
          <td>{item.tipo === "1" ? "Ergonomia" : "Acidentes"}</td>
          <td className="text-center">
            <Botao
              icone="file-pdf"
              cor="secondary"
              alvo={`${process.env.REACT_APP_CAMINHO_UPLOAD}uploadPDF/${item.url}`}
            />
          </td>
          {dadosToken.tipo <= "1" && (
            <td className="text-center">
              <BotaoDeletar onClick={() => deletarPDF(item.id, item.url)} />
            </td>
          )}
        </tr>
      </>
    );
  };

  const tituloSpan =
    dadosToken.tipo <= "1"
      ? ergonomia
        ? "Upload Ergonomia"
        : "Upload Acidente"
      : ergonomia
      ? "Ergonomia"
      : "Acidente";
  return (
    <ModalSubmit
      show={show}
      setShow={setShow}
      titulo={botao}
      tituloSpan={tituloSpan}
      tamanho="md"
      onSubmit={dadosToken.tipo <= "1" ? (e) => handleSubmit(e, arquivo) : false}
    >
      <>
        {dadosToken.tipo <= "1" && (
          <>
            <div className="row">
              <h6>Cliente: {dadosCliente?.nome_fantasia}</h6>
            </div>
            <div className="row">
              <div className="col">
                <Select
                  id="selectEstabelecimento"
                  name="select_estabelecimento"
                  placeholder="Selecione"
                  onChange={(e) => {
                    setEstabelecimentoSelecionado(e.value);
                    if (e.tipo === "S" || e.tipo === "SE") {
                      setIsSede(true);
                    }
                    setEstabelecimentoSelecionadoNome(e.label.replace(/\s/g, ""));
                  }}
                  options={estabelecimentoOption}
                />
              </div>
              <div className="row">
                <div className="col mt-4">
                  <InputFile nomeArquivo={arquivo} handleChange={(arquivo) => setArquivo(arquivo)} />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <TabelaPadrao
            colunas={[
              { nome: "Estabelecimento" },
              { nome: "Arquivo" },
              { nome: "Visualizar", width: "5%", text: "center" },
              dadosToken.tipo <= "1" && {
                nome: "Ações",
                width: "5%",
                text: "center",
              },
            ]}
            itens={dadosUploadPDF?.dados_cliente}
          >
            {dadosUploadPDF?.dados_cliente?.map((item, index) => (
              <TabelaDados index={index} item={item} />
            ))}
          </TabelaPadrao>
        </div>
      </>
    </ModalSubmit>
  );
};

const ModalHistoricoRevisoes = ({ show, setShow }) => {
  const [estabelecimentos, setEstabelecimentos] = useState();
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState();
  const [carregando, setCarregando] = useState(true);
  const { clienteId } = useParams();

  useEffect(() => {
    api.get(`ppras/obterEstabelecimentosComRevisoes/${clienteId}`).then((resposta) => {
      setEstabelecimentos(resposta.data);
      setCarregando(false);
    });
  }, [clienteId]);

  let revisoes = estabelecimentos?.find((item) => item.codigo === estabelecimentoSelecionado);

  const Estabelecimentos = ({ item }) => {
    return (
      <tr style={{ cursor: "pointer" }} onClick={() => setEstabelecimentoSelecionado(item.codigo)}>
        <td className="">{item.estabelecimento}</td>
      </tr>
    );
  };

  return (
    <ModalPadrao show={show} setShow={setShow} titulo={"Histórico de Revisão"} tamanho="lg" color="primary">
      {carregando ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col">
              <table className="table table-hover">
                <tbody>
                  {estabelecimentos?.map((item, index) => (
                    <Estabelecimentos item={item} key={index} />
                  ))}
                </tbody>
              </table>
            </div>
            {!isEmpty(estabelecimentoSelecionado) && (
              <>
                <div className="col">
                  <table className="table table-hover">
                    <tbody>
                      <Revisoes revisoes={revisoes} />
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </ModalPadrao>
  );
};

const Revisoes = ({ revisoes }) => {
  return (
    <>
      {/* PGR */}
      <Card className="row">
        <h5 className="text-center">PGR</h5>

        {revisoes.revisoes?.pgr.map((item) => (
          <>
            <span style={{ cursor: "pointer" }} className="text-center border-bottom pb-1">
              {item.revisao}
            </span>
          </>
        ))}

        {/* ppra */}

        <h5 className="text-center">PPRA</h5>
        {revisoes.revisoes?.ppra.map((item) => (
          <>
            <span className="text-center border-bottom pb-1">{item.revisao}</span>
          </>
        ))}
      </Card>
    </>
  );
};

const ModalAET = ({ show, setShow }) => {
  const [cliente, setCliente] = useState();
  const [carregando, setCarregando] = useState(true);
  const [carregandoEstabelecimento, setCarregandoEstabelecimento] = useState();
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState();

  const { clienteId } = useParams();

  useEffect(() => {
    api.get(`clientes/obterClienteComPGR/${clienteId}`).then((resposta) => {
      setCliente(resposta.data);
      setCarregando(false);
    });
  }, [clienteId]);

  const optionsEstabelecimentos = cliente?.estabelecimentos?.map((item) => ({
    label: item.nome_generico,
    value: item,
  }));

  function selecionaEstabelecimento(estabelecimento) {
    setEstabelecimentoSelecionado(estabelecimento);
    setTimeout(() => {
      setCarregandoEstabelecimento(false);
    }, "500");
  }

  return (
    <>
      <ModalPadrao tamanho="md" setShow={setShow} show={show} titulo="AET - Ergonomia">
        {carregando ? (
          <Loader />
        ) : (
          <>
            {cliente.estabelecimentos?.length > 1 ? (
              <>
                <div className="row">
                  <div className="col">
                    <Select
                      options={optionsEstabelecimentos}
                      onChange={(e) => {
                        setCarregandoEstabelecimento(true);
                        selecionaEstabelecimento(e.value);
                      }}
                    />
                  </div>
                </div>
                {!isEmpty(estabelecimentoSelecionado) && (
                  <>
                    {isEmpty(estabelecimentoSelecionado.documento_base_id) && (
                      <div className="row mt-4">
                        <div className="col">
                          {dadosToken().tipo === "2" ? "Documento em produção" : "Necessita gerar documento base"}
                        </div>
                      </div>
                    )}
                    <div className="row mt-5">
                      <div className="col">
                        {carregandoEstabelecimento ? (
                          <Loader />
                        ) : (
                          <>
                            <div className="col">
                              <div className="btn-group-vertical w-100 h-150">
                                <div className="btn-group h-50">
                                  <Botao
                                    className="w-100"
                                    conteudo="AET"
                                    icone="print"
                                    cor="cinza"
                                    onClick={() => {
                                      if (estabelecimentoSelecionado.revisoes.tipo_servico) {
                                        toast.warning(
                                          dadosToken().tipo === "2"
                                            ? "Documento em produção"
                                            : "Cadastre os tipos de serviços nas atividades!"
                                        );
                                      } else {
                                        window.open(
                                          process.env.REACT_APP_API_URL +
                                            `/gro/pgr/pdf/apres/gerarAET/${estabelecimentoSelecionado.revisoes.id}`
                                        );
                                      }
                                    }}
                                    outline
                                    disabled={isEmpty(estabelecimentoSelecionado.documento_base_id)}
                                  />
                                  <Botao
                                    className="w-100"
                                    conteudo="AET - Resumo"
                                    icone="print"
                                    cor="cinza"
                                    onClick={() => {
                                      if (estabelecimentoSelecionado.revisoes.tipo_servico) {
                                        toast.warning(
                                          dadosToken().tipo === "2"
                                            ? "Documento em produção"
                                            : "Cadastre os tipos de serviços nas atividades!"
                                        );
                                      } else {
                                        window.open(
                                          process.env.REACT_APP_API_URL +
                                            `/gro/pgr/pdf/apres/gerarAETResumo/${estabelecimentoSelecionado.revisoes.id}`
                                        );
                                      }
                                    }}
                                    outline
                                    disabled={isEmpty(estabelecimentoSelecionado.documento_base_id)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {isEmpty(cliente.estabelecimentos[0].documento_base_id) ? (
                  <>{dadosToken().tipo === "2" ? <>PDF em produção</> : <>Necessita da criação do documento base</>}</>
                ) : (
                  <>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="btn-group-vertical w-100 h-150">
                          <div className="btn-group h-50">
                            <Botao
                              className="w-100"
                              conteudo="Gerar PDF"
                              icone="print"
                              cor="cinza"
                              onClick={() => {
                                if (isEmpty(cliente.estabelecimentos[0].revisoes)) {
                                  if (dadosToken().tipo === "2") {
                                    toast.info("PDF em produção");
                                  } else {
                                    toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                                  }
                                } else {
                                  if (cliente.estabelecimentos[0].revisoes.tipo_servico) {
                                    toast.warning(
                                      dadosToken().tipo === "2"
                                        ? "Documento em produção"
                                        : "Cadastre os tipos de serviços nas atividades!"
                                    );
                                  } else {
                                    window.open(
                                      process.env.REACT_APP_API_URL +
                                        `/gro/pgr/pdf/apres/gerarAET/${cliente.estabelecimentos[0].revisoes.id}`
                                    );
                                  }
                                }
                              }}
                              outline
                            />
                            <Botao
                              className="w-100"
                              conteudo="Gerar PDF - Resumo"
                              icone="print"
                              cor="cinza"
                              onClick={() => {
                                if (isEmpty(cliente.estabelecimentos[0].revisoes)) {
                                  if (dadosToken().tipo === "2") {
                                    toast.info("Documento em produção");
                                  } else {
                                    toast.info("Revisão não foi revisada ou não tem nenhuma revisão cadastrada");
                                  }
                                } else {
                                  if (cliente.estabelecimentos[0].revisoes.tipo_servico) {
                                    toast.warning(
                                      dadosToken().tipo === "2"
                                        ? "Documento em produção"
                                        : "Cadastre os tipos de serviços nas atividades!"
                                    );
                                  } else {
                                    window.open(
                                      process.env.REACT_APP_API_URL +
                                        `/gro/pgr/pdf/apres/gerarAETResumo/${cliente.estabelecimentos[0].revisoes.id}`
                                    );
                                  }
                                }
                              }}
                              outline
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ModalPadrao>
    </>
  );
};

export default Pgr;
