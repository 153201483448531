import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { BotaoAdicionar } from "../../../components/Botao";
import ModalPadrao from "../../../components/ModalPadrao";
import NavTabs from "../../../components/NavTabs";
import Riscos from "./Riscos";
import { isEmpty } from "../../../components/Util";

const Severidade = ({ matematica, riscoId, nomeAtividade, setor, formaRisco, carregarAmbientes }) => {
  const [showModalSeveridade, setShowModalSeveridade] = useState(false);
  const history = useHistory();

  return (
    <div className="row mb-2">
      <div className="col-1">
        <BotaoAdicionar
          className="mr-1"
          onClick={() => {
            setShowModalSeveridade(true);
            history.push("reconhecimentos#1");
          }}
          disabled={isEmpty(matematica)}
        />
        {showModalSeveridade && (
          <ModalSeveridade
            nomeAtividade={nomeAtividade}
            riscoId={riscoId}
            item={matematica.id}
            show={showModalSeveridade}
            setShow={setShowModalSeveridade}
            setor={setor}
            formaRisco={formaRisco}
            carregarAmbientes={carregarAmbientes}
          />
        )}
      </div>
      <div className="col-2 gx-0">
        <span className="text-muted mr-1">Severidade</span>
      </div>
      <div className="col gx-0">
        {!isEmpty(matematica?.riscos_cadastrados) &&
          matematica?.riscos_cadastrados?.map((risco, index) => (
            <>
              <span className="badge bg-primary mr-1">{risco.adjetivo + " " + risco.risco}</span>
            </>
          ))}
      </div>
    </div>
  );
};

const ModalSeveridade = ({ nomeAtividade, riscoId, item, show, setShow, setor, formaRisco, carregarAmbientes }) => {
  const acoes = [
    {
      id: 1,
      nome: "Riscos / Adjetivos",
      body: (
        <Riscos
          matematicaId={item}
          riscoId={riscoId}
          setor={setor}
          formaRisco={formaRisco}
          carregarAmbientes={carregarAmbientes}
        />
      ),
    },
  ];

  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      titulo={"Severidade do ambiente/atividade:"}
      tituloSpan={nomeAtividade}
      handleHide={() => carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id)}
    >
      <NavTabs acoes={acoes} />
    </ModalPadrao>
  );
};

export default Severidade;
