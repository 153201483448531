import React, { useEffect, useState } from "react";

import {
  BotaoAdicionar,
  BotaoEditar,
  BotaoGrupo,
  BotaoRemover,
  BotaoSalvar,
  BotaoVoltar,
} from "../../../../components/Botao";
import ModalPadrao from "../../../../components/ModalPadrao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import Filtros from "../../components/Filtros";

const AdicionarLimitesPpra = ({
  showAdicionaLimitePpra,
  setShowAdicionaLimitePpra,
  formaRisco,
  quimico,
  carregarAmbientes,
  setor,
}) => {
  const [limites, setLimites] = useState([]);
  const [limitesPpra, setLimitesPpra] = useState([]);

  const [filtroLimites, setFiltroLimites] = useState("");
  const [filtroLimitesPpra, setFiltroLimitesPpra] = useState("");

  const [carregandoLimites, setCarregandoLimites] = useState(true);
  const [carregandoLimitesPpra, setCarregandoLimitesPpra] = useState(true);

  let limitesFiltrados = limites.filter((limite) =>
    filtrar(limite.elemento_nome, filtroLimites)
  );

  let limitesPpraFiltrados = limitesPpra.filter((limitePpra) =>
    filtrar(limitePpra.elemento_nome, filtroLimitesPpra)
  );

  function filtrarLimites(newFilter) {
    setFiltroLimites(newFilter);
  }

  function filtrarLimitesPpra(newFilter) {
    setFiltroLimitesPpra(newFilter);
  }

  useEffect(() => {
    carregarLimites(
      quimico.tarefa_id ? quimico.tarefa_id : null,
      quimico.local_quadro_id,
      formaRisco.id,
      quimico.produto_quimico_id
    );
    carregarLimitesPpra(
      quimico.tarefa_id ? quimico.tarefa_id : null,
      quimico.local_quadro_id,
      formaRisco.id,
      quimico.produto_quimico_id
    );
  }, [
    quimico.tarefa_id,
    quimico.local_quadro_id,
    formaRisco.id,
    quimico.produto_quimico_id,
  ]);

  async function carregarLimites(
    tarefaId,
    localQuadroId,
    formaRiscoId,
    produtoQuimicoId
  ) {
    await api
      .get("/ppra/limite/obterLimites", {
        params: {
          tarefa_id: tarefaId,
          local_quadro_id: localQuadroId,
          forma_risco_id: formaRiscoId,
          produto_quimico_id: produtoQuimicoId,
        },
      })
      .then((resposta) => {
        setLimites(resposta.data);
        setCarregandoLimites(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  async function carregarLimitesPpra(
    tarefaId,
    localQuadroId,
    formaRiscoId,
    produtoQuimicoId
  ) {
    await api
      .get(`/ppra/limite/obterLimitesReconhecimento`, {
        params: {
          tarefa_id: tarefaId,
          local_quadro_id: localQuadroId,
          forma_risco_id: formaRiscoId,
          produto_quimico_id: produtoQuimicoId,
        },
      })
      .then((resposta) => {
        setLimitesPpra(resposta.data);
        setCarregandoLimitesPpra(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  return (
    <ModalPadrao
      show={showAdicionaLimitePpra}
      setShow={setShowAdicionaLimitePpra}
      id={
        "modalAdicionaLimites-" + quimico.tarefa_id
          ? quimico.tarefa_id
          : quimico.local_quadro_id
      }
      titulo={"Adicionar limites"}
      handleHide={() =>
        carregarAmbientes(
          setor.setor_id,
          setor.ppra_id,
          formaRisco.risco_id,
          formaRisco.id
        )
      }
    >
      <Filtros
        itens={[
          {
            placeholder: "Limites não adicionados",
            filtrar: filtrarLimites,
            atualizarItens: () => {
              setCarregandoLimites(true);
              carregarLimites(
                quimico.tarefa_id ? quimico.tarefa_id : null,
                quimico.local_quadro_id,
                formaRisco.id,
                quimico.produto_quimico_id
              );
            },
            carregando: carregandoLimites,
          },
          {
            placeholder: "Limites adicionados",
            filtrar: filtrarLimitesPpra,
            carregando: carregandoLimitesPpra,
          },
        ]}
      />
      <div className="row">
        <div className="col">
          <TabelaPadrao
            colunas={[
              {
                nome: (
                  <>
                    Nome do elemento
                    <span className="badge bg-primary ml-2">
                      Tipo de limite
                    </span>
                  </>
                ),
              },
              { nome: "Ação", text: "center", width: "10%" },
            ]}
            itens={limites}
            itensFiltrados={limitesFiltrados}
            filtro={filtroLimites}
            carregando={carregandoLimites}
          >
            {limitesFiltrados.map((limite, index) => (
              <Limite
                key={index}
                limite={limite}
                index={index}
                adicionaLimite
                limites={limitesFiltrados}
                quimico={quimico}
                formaRiscoId={formaRisco.id}
                carregarLimitesPpra={carregarLimitesPpra}
                carregarLimites={carregarLimites}
                setCarregandoLimitesPpra={setCarregandoLimitesPpra}
                setCarregandoLimites={setCarregandoLimites}
              />
            ))}
          </TabelaPadrao>
        </div>
        <div className="col">
          <TabelaPadrao
            colunas={[
              {
                nome: (
                  <>
                    Nome do elemento
                    <span className="badge bg-primary ml-2">
                      Tipo de limite
                    </span>
                  </>
                ),
              },
              { nome: "Observação", width: "35%" },
              { nome: "Ações", text: "center", width: "16%" },
            ]}
            itens={limitesPpra}
            itensFiltrados={limitesPpraFiltrados}
            filtro={filtroLimitesPpra}
            carregando={carregandoLimitesPpra}
          >
            {limitesPpraFiltrados.map((limite, index) => (
              <Limite
                key={index}
                limite={limite}
                index={index}
                limites={limitesPpraFiltrados}
                quimico={quimico}
                formaRiscoId={formaRisco.id}
                carregarLimitesPpra={carregarLimitesPpra}
                carregarLimites={carregarLimites}
                setCarregandoLimitesPpra={setCarregandoLimitesPpra}
                setCarregandoLimites={setCarregandoLimites}
              />
            ))}
          </TabelaPadrao>
        </div>
      </div>
    </ModalPadrao>
  );
};

const Limite = ({
  limite,
  index,
  adicionaLimite,
  quimico,
  formaRiscoId,
  carregarLimitesPpra,
  carregarLimites,
  setCarregandoLimitesPpra,
  setCarregandoLimites,
}) => {
  const [editaObservacao, setEditaObservacao] = useState(false);

  async function adicionarLimitePpra(quimico, limiteId) {
    setCarregandoLimitesPpra(true);
    setCarregandoLimites(true);
    await api
      .post("ppra/Limite/inserir", {
        limite_id: limiteId,
        tarefa_id: quimico.tarefa_id ? quimico.tarefa_id : null,
        local_quadro_id: quimico.local_quadro_id,
        forma_risco_id: formaRiscoId,
        produto_quimico_id: quimico.produto_quimico_id,
      })
      .then(() => {
        carregarLimitesPpra(
          quimico.tarefa_id,
          quimico.local_quadro_id,
          formaRiscoId,
          quimico.produto_quimico_id
        );
        carregarLimites(
          quimico.tarefa_id,
          quimico.local_quadro_id,
          formaRiscoId,
          quimico.produto_quimico_id
        );
      })
      .catch((erro) => {
        alert(erro.response.data.mensagem);
      });
  }

  const observacaoLimite = {};

  function preencherObservacao(evento) {
    var nome = evento.target.name;
    var valor = evento.target.value;
    if (nome === "observacao") {
      observacaoLimite.observacao = valor === "" ? null : valor;
    }
  }

  async function cadastrarObservacao(id) {
    await api
      .put("ppra/limite/editar/" + id, {
        observacao: observacaoLimite.observacao,
      })
      .then(() => {
        carregarLimitesPpra(
          quimico.tarefa_id,
          quimico.local_quadro_id,
          formaRiscoId,
          quimico.produto_quimico_id
        );
        setEditaObservacao(false);
      });
  }

  async function deletarLimitePpra(quimico, limite) {
    if (
      window.confirm(
        `Tem certeza que deseja remover o limite do elemento: ${limite.elemento_nome} deste quimico?`
      )
    ) {
      setCarregandoLimites(true);
      setCarregandoLimitesPpra(true);
      await api
        .delete(`ppra/Limite/deletar/${limite.limite_reconhecimento_id}`)
        .then(() => {
          carregarLimitesPpra(
            quimico.tarefa_id,
            quimico.local_quadro_id,
            formaRiscoId,
            quimico.produto_quimico_id
          );
          carregarLimites(
            quimico.tarefa_id,
            quimico.local_quadro_id,
            formaRiscoId,
            quimico.produto_quimico_id
          );
        })
        .catch((erro) => {
          alert(erro.response.data.mensagem);
          setCarregandoLimites(false);
          setCarregandoLimitesPpra(false);
        });
    }
  }

  return (
    <>
      <tr key={index}>
        <td>
          {limite.elemento_nome} {limite.nomeNorma} ({limite.nomeParteNorma}) -{" "}
          {limite.valor}
          {limite.nomeTipoLimite && (
            <span className="badge bg-primary ml-2">
              {limite.nomeTipoLimite}
            </span>
          )}
        </td>
        <td>{limite.observacao || "-"}</td>
        <td>
          <div className="text-center">
            {adicionaLimite ? (
              <BotaoAdicionar
                title="Adicionar limite"
                onClick={() => adicionarLimitePpra(quimico, limite.limite_id)}
              />
            ) : (
              <BotaoGrupo>
                {editaObservacao ? (
                  <BotaoVoltar onClick={() => setEditaObservacao(false)} />
                ) : (
                  <BotaoEditar onClick={() => setEditaObservacao(true)} />
                )}
                <BotaoRemover
                  title="Remover limite"
                  onClick={() => deletarLimitePpra(quimico, limite)}
                />
              </BotaoGrupo>
            )}
          </div>
        </td>
      </tr>
      {editaObservacao && (
        <tr>
          <td colSpan="3">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Observação</label>
              <div className="input-group">
                <input
                  className="form-control"
                  maxLength="50"
                  type="text"
                  name="observacao"
                  defaultValue={limite.observacao || ""}
                  onChange={(e) => preencherObservacao(e)}
                />
                <div className="input-group-append">
                  <BotaoSalvar
                    type="submit"
                    valor="Salvar"
                    onClick={() =>
                      cadastrarObservacao(limite.limite_reconhecimento_id)
                    }
                  />
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default AdicionarLimitesPpra;
