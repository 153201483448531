import React from "react";
import { Button } from "react-bootstrap";

import { isEmpty } from "../../../components/Util";
import Dica from "../../../components/Dica";
import Icone from "../../../components/Icone";

const ToolTipItem = ({ item, isQuimico, isAmbiente, onClick }) => {
  if (
    isEmpty(item.limites_reconhecimento) &&
    isEmpty(item.medidas_organizacionais) &&
    isEmpty(item.epcs_reconhecimento_riscos)
  ) {
    return (
      <Button variant="primary" size="sm" title="Funções" onClick={onClick}>
        <Icone icone="cogs" />
      </Button>
    );
  }

  return (
    <Dica
      id={isAmbiente ? item.local_quadro_id : item.atividade_id}
      conteudo={
        <>
          <div>
            {!isEmpty(item.medidas_organizacionais) && (
              <>
                <div className="font-italic text-info">Medidas: </div>
                {item.medidas_organizacionais.map((medida, index) => (
                  <React.Fragment key={index}>
                    {medida.definicao}
                    {isAmbiente && " (" + medida.quantidade + ")"}

                    {index !== item.medidas_organizacionais.length - 1 && ", "}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
          <div>
            {!isEmpty(item.epcs_reconhecimento_riscos) && (
              <>
                <div className="font-italic text-info">EPC's: </div>
                {item.epcs_reconhecimento_riscos.map((epc, index) => (
                  <React.Fragment key={index}>
                    {epc.nome}
                    {isAmbiente && " (" + epc.quantidade + ")"}

                    {index !== item.epcs_reconhecimento_riscos.length - 1 &&
                      ", "}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
          <div>
            {isQuimico && !isEmpty(item.limites_reconhecimento) && (
              <>
                {!isEmpty(item.limites_reconhecimento) && (
                  <>
                    <div className="font-italic text-info">Limites: </div>
                    {item.limites_reconhecimento.map((limite, index) => (
                      <React.Fragment key={index}>
                        {limite.elemento_nome} {limite.nomeNorma} (
                        {limite.nomeParteNorma}) - {limite.valor}
                        {isAmbiente && " (" + limite.quantidade + ")"}
                        {index !== item.limites_reconhecimento.length - 1 &&
                          ", "}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </>
      }
    >
      <Button variant="success" size="sm" title="Funções" onClick={onClick}>
        <Icone icone="cogs" />
      </Button>
    </Dica>
  );
};

export default ToolTipItem;
