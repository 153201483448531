import Select from "react-select";
import { useEffect, useState } from "react";

import moment from "moment";
import api from "../../../service/api";
import { toast } from "react-toastify";
import InputFile from "../../../components/InputFile";
import ModalPadrao from "../../../components/ModalPadrao";
import TabelaPadrao from "../../../components/TabelaPadrao";
import { formataNome, isEmpty } from "../../../components/Util";
import Botao, { BotaoDeletar, BotaoEditar, BotaoGrupo, BotaoSalvar, BotaoVoltar } from "../../../components/Botao";

const ModalAvaliacaoPsicossocial = ({ show, setShow, estabelecimentos, clienteId, dadosToken }) => {
  const [arquivo, setArquivo] = useState();
  const [nomeDocumento, setNomeDocumento] = useState();
  const [revisao, setRevisao] = useState();
  const [data, setData] = useState();
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState();
  const [dados, setDados] = useState();

  useEffect(() => {
    obterDadosTabela(clienteId);
  }, [clienteId]);

  async function obterDadosTabela(clienteId) {
    await api.get(`ppras/obterAvalicaoPsicossocial/${clienteId}`).then((resposta) => {
      setDados(resposta.data);
    });
  }

  function handleSubmit(e, arquivo) {
    if (!estabelecimentoSelecionado) {
      toast.info("Selecione um estabelecimento");
    } else if (!data) {
      toast.info("Selecione uma data");
    } else {
      async function uploadArquivo(e, arquivo) {
        e.preventDefault();

        const novoNome = `${formataNome(nomeDocumento)}_${estabelecimentoSelecionado}`;
        const formData = new FormData();
        formData.append("arquivo", arquivo);
        formData.append("nomeNovo", novoNome);
        formData.append("nomeArquivo", arquivo.name);
        formData.append("caminho", `${estabelecimentoSelecionado}`);
        formData.append("clienteEstabelecimento", estabelecimentoSelecionado);

        // Upload
        await api
          .post("/upload/inserirAvaliacaoPsicossocial", formData, {
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          })
          .then((resposta) => inserir(resposta.data.nomeArquivo))
          .catch((e) => alert(e.response.data.mensagem));

        obterDadosTabela(clienteId);
      }
      uploadArquivo(e, arquivo);
    }
  }

  async function inserir(novoNome) {
    await api
      .post("ppras/inserirPDFAvaliacaoPsicossocial", {
        cliente_id: clienteId,
        cliente_estabelecimento_id: estabelecimentoSelecionado,
        nome: nomeDocumento,
        revisao: revisao,
        url: novoNome,
        data: data,
      })
      .then(() => {
        obterDadosTabela(clienteId);
        toast.success("Documento adicionado com sucesso");
      });
  }

  async function deletar(nomeArquivo, clienteEstabelecimento, idDeletar) {
    if (window.confirm(`Você tem certeza que deseja deletar este PDF?`)) {
      api
        .post(`upload/deletarArquivo`, {
          nome_arquivo: nomeArquivo,
          caminho: `avaliacaoPsicossocial/${clienteEstabelecimento}`,
        })
        .then(() => {
          deletarDocumento(idDeletar);
        });
    }
  }

  async function deletarDocumento(idDeletar) {
    await api.delete(`ppras/deletarPdfAtividadePsicossocial/${idDeletar}`).then(() => {
      obterDadosTabela(clienteId);
      toast.success("Documento deletado com sucesso");
    });
  }

  const TabelaDocumentos = ({ item, index }) => {
    const [editando, setEditando] = useState(false);
    const [novoEstabelecimento, setNovoEstabelecimento] = useState();
    const [novoNome, setNovoNome] = useState();
    const [novaData, setNovData] = useState();
    const [novaRevisao, setNovaRevisao] = useState();

    async function editar(e, idEditar, novoEstabelecimento, novoNome) {
      e.preventDefault();
      await api
        .put(`ppras/editarPdfAvalicaoPsicossocial/${idEditar}`, {
          cliente_estabelecimento_id: novoEstabelecimento,
          nome: novoNome,
          data: novaData,
          revisao: novaRevisao,
        })
        .then(() => {
          obterDadosTabela(clienteId);
          toast.success("Documento editado com sucesso");
        });
    }

    return (
      <>
        <tr key={index}>
          {editando ? (
            <>
              <td>
                <Select
                  defaultValue={isEmpty(item.nome_generico) ? { label: item.nome_fantasia } : { label: item.nome_generico }}
                  onChange={(e) => {
                    setNovoEstabelecimento(e.value);
                  }}
                  options={estabelecimentos.map((cliEstab) => ({
                    label: cliEstab.estabelecimento.nome_generico,
                    value: cliEstab.codigo,
                  }))}
                />
              </td>
              <td>
                <input type="text" defaultValue={item.nome} className="form-control" onChange={(e) => setNovoNome(e.target.value)} />
              </td>

              <td>
                <input type="text" defaultValue={item.revisao} className="form-control" onChange={(e) => setNovaRevisao(e.target.value)} />
              </td>
              <td>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={item.data}
                  name="dataDocumento"
                  id="dataDocumento"
                  onChange={(e) => setNovData(e.target.value)}
                />
              </td>
            </>
          ) : (
            <>
              <td>{isEmpty(item.nome_generico) ? item.nome_fantasia : item.nome_generico}</td>
              <td>{item.nome}</td>
              <td className={isEmpty(item.revisao) && "text-center"}>{isEmpty(item.revisao) ? " - " : item.revisao}</td>
              <td>{item.data === "0000-00-00" ? " - " : moment(item.data).format("DD/MM/YYYY")}</td>
            </>
          )}
          <td className="text-center">
            <Botao
              icone="file-pdf"
              cor="secondary"
              alvo={process.env.REACT_APP_CAMINHO_UPLOAD + `avaliacaoPsicossocial/${item.cliente_estabelecimento_id}/` + item.url}
            />
          </td>
          {dadosToken.tipo <= "1" && (
            <td className="text-center">
              {editando ? (
                <BotaoGrupo>
                  <BotaoSalvar onClick={(e) => editar(e, item.id, novoEstabelecimento, novoNome)} />
                  <BotaoVoltar onClick={() => setEditando(!editando)} />
                </BotaoGrupo>
              ) : (
                <BotaoGrupo>
                  <BotaoEditar onClick={() => setEditando(!editando)} />
                  <BotaoDeletar onClick={() => deletar(item.url, item.cliente_estabelecimento_id, item.id)} />
                </BotaoGrupo>
              )}
            </td>
          )}
        </tr>
      </>
    );
  };

  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      tamanho="lg"
      titulo={"Avaliação Psicossocial (Externa)"}
      tituloSpan={dadosToken.tipo <= "1" ? "Upload" : "PDF"}
    >
      <>
        {dadosToken.tipo <= "1" && (
          <div className="row">
            <div className="col-12">
              <p>
                O tamanho do arquivo deve ser menor ou igual a <span className="badge bg-success">4500kB</span>
                <br />
                Arquivo pode ser em formato:
                <span className="badge bg-success">.pdf</span>
                <span className="badge bg-success ml-1">.jpg</span>
                <span className="badge bg-success ml-1">.jpeg</span>
                <span className="badge bg-success ml-1">.xls</span>
                <span className="badge bg-success ml-1">.xlsx</span>
                <span className="badge bg-success ml-1">.excel</span>
              </p>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-4 mt-2 mr-2">
                  <label>Selecione um estabelecimento: </label>
                </div>
                <div className="col">
                  <Select
                    onChange={(e) => {
                      setEstabelecimentoSelecionado(e.value);
                    }}
                    options={estabelecimentos.map((cliEstab) => ({
                      label: cliEstab.estabelecimento.nome_generico,
                      value: cliEstab.codigo,
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {dadosToken.tipo <= "1" && (
          <>
            <div className="row mt-3">
              <div className="col-auto mr-2">
                <label className="form-label mr-1 mt-1" htmlFor="documento">
                  Documento:
                </label>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="documento"
                  onChange={(e) => setNomeDocumento(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-1 mr-2">
                <label className="form-label mr-3 mt-1" htmlFor="revisao">
                  Revisão:
                </label>
              </div>
              <div className="col-2">
                <input type="text" className="form-control form-control-sm" id="revisao" onChange={(e) => setRevisao(e.target.value)} required />
              </div>

              <div className="col-auto mt-1">
                <label htmlFor="">Data:</label>
              </div>
              <div className="col-2">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  onChange={(e) => setData(e.target.value)}
                  name="dataDocumento"
                  id="dataDocumento"
                />
              </div>
              <div className="col-5">
                <InputFile nomeArquivo={arquivo} handleChange={(arquivo) => setArquivo(arquivo)} />
              </div>
              <div className="col mt-2 mr-2">
                <BotaoSalvar temConteudo className="float-end mb-4 ml-4" onClick={(e) => handleSubmit(e, arquivo)} />
              </div>
            </div>
          </>
        )}
        <div className="row">
          <TabelaPadrao
            colunas={[
              { nome: "Estabelecimento" },
              { nome: "Arquivo" },
              { nome: "Revisão" },
              { nome: "Data" },
              { nome: "Visualizar", width: "10%", text: "center" },
              dadosToken.tipo <= 1 && {
                nome: "Ações",
                width: "13%",
                text: "center",
              },
            ]}
            itens={dados}
          >
            {dados?.map((item, index) => (
              <TabelaDocumentos item={item} index={index} />
            ))}
          </TabelaPadrao>
        </div>
      </>
    </ModalPadrao>
  );
};

export default ModalAvaliacaoPsicossocial;
